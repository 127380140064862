<template>
  <div class="CHD_Info">
<!--    {{questionnaire_info}}-->
    <h3>医生信息</h3>
    <el-form :model="info_form" ref="info_form_add" label-width="100px" class="CHD_Info_form"  label-position="top" >
      <el-form-item
          prop="name"
          label="姓名"
          :rules="[{ required: true, message: '请输入姓名', trigger: 'blur' },]"
      >
        <el-input v-model="info_form.name"></el-input>
      </el-form-item>
      <el-form-item
          prop="hospitalName"
          label="医院"
          :rules="[{ required: true, message: '请输入医院', trigger: 'blur' },]"
      >
        <el-input v-model="info_form.hospitalName"></el-input>
      </el-form-item>
      <el-form-item
          prop="hospitalOffices"
          label="科室"
          :rules="[{ required: true, message: '请输入科室', trigger: 'blur' },]"
      >
        <el-input v-model="info_form.hospitalOffices" />
      </el-form-item>
      <el-form-item
          prop="jobTitle"
          label="职称"
          :rules="[{ required: true, message: '请输入职称', trigger: 'blur' },]"
      >
        <el-input v-model="info_form.jobTitle" />
      </el-form-item>
      <el-form-item
          label="手机号"
      >
        <el-input v-model="info_form.tel"  :disabled="true" />
      </el-form-item>

    <h3>账户信息</h3>
      <el-form-item
          prop="name"
          label="户名"
      >
        <el-input v-model="info_form.name" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item
          prop="idCard"
          label="身份证号"
          :rules="[{ required: true, message: '请输入身份证号', trigger: 'blur' },]"
      >
        <el-input v-model="info_form.idCard"></el-input>
      </el-form-item>
      <el-form-item
          prop="idCardImg"
          label="身份证正反面"
          :rules="[{ required: true, message: '请上传身份证', trigger: 'change' },]"
      >
        <van-uploader v-model="info_form.idCardImg" :after-read="afterRead" :max-count="2" />
      </el-form-item>
      <el-form-item
          prop="bankName"
          label="开户行"
          :rules="[{ required: true, message: '请输入开户行', trigger: 'blur' },]"
      >
        <el-input v-model="info_form.bankName" />
      </el-form-item>
      <el-form-item
          prop="bankCard"
          label="银行卡号"
          :rules="[{ required: true, message: '请输入银行卡号', trigger: 'blur' },]"
      >
        <el-input v-model="info_form.bankCard" />
      </el-form-item>
      <el-form-item
          prop="bankCardImg"
          label="银行卡照片"

      >
<!--        :rules="[{ required: true, message: '请上传银行卡照片', trigger: 'change' },]"-->
        <van-uploader v-model="info_form.bankCardImg" :after-read="afterRead2" :max-count="1" />
      </el-form-item>
    </el-form>

    <div class="CHD_Info_bottom">
      <el-button type="primary"  @click="addOk">提交并选择模板</el-button>
    </div>
<!--    <van-popup-->
<!--        v-model="show"-->
<!--        closeable-->
<!--        position="bottom"-->
<!--        :style="{ height: '40%' }"-->
<!--    >-->
<!--      <div style="padding: 20px;margin-top: 20px">-->
<!--        <div v-for="list in case_modes" >-->
<!--          <h3 @click="mode_url(list)">{{list.title}}</h3>-->
<!--        </div>-->
<!--      </div>-->
<!--    </van-popup>-->
  </div>
</template>
<script>

import {memberInfo, post_caseModes, put_memberInfo, uploadImg} from "../../service/api";

export default {
  name: "CHD_Info",
  data(){
    return{
      info_form:{
        idCardImg:[],
        bankCardImg:[],
      },
      show:false,
      case_modes:[],
      questionnaire_info:''
    }
  },
  mounted() {
    document.title="选择模板"
    this.getMemberInfo()
  },
  methods:{

    async afterRead(file){
      if (file.file.size >20971520){
        this.$message.error("请上传小于20M的文件！")
      }else {
        file.status = 'uploading';
        file.message = '上传中...';
        let formData = new FormData()
        formData.append('file', file.file)
        const upload = await uploadImg(formData)
        if (upload.code===0){
          this.info_form.idCardImg[this.info_form.idCardImg.length-1].url=upload.data.url
          file.status = 'done';
          file.message = '上传成功';
        }else {
          file.status = 'failed';
          file.message = '上传失败';
        }
      }
    },

    async afterRead2(file){
      if (file.file.size >20971520){
        this.$message.error("请上传小于20M的文件！")
      }else {
        file.status = 'uploading';
        file.message = '上传中...';
        let formData = new FormData()
        formData.append('file', file.file)
        const upload = await uploadImg(formData)
        if (upload.code===0){
          this.info_form.bankCardImg[this.info_form.bankCardImg.length-1].url=upload.data.url
          file.status = 'done';
          file.message = '上传成功';
        }else {
          file.status = 'failed';
          file.message = '上传失败';
        }
      }
    },

     async getMemberInfo(){
      const info = await memberInfo(this.$route.query.created_by)
       if (info.code===0){
         this.info_form= info.data
         this.info_form.idCardImg = this.info_form.idCardImg !== null ? this.info_form.idCardImg.map(value => ({ url: value })) : [];
         this.info_form.bankCardImg = this.info_form.bankCardImg !== null ? [{url:this.info_form.bankCardImg}] : [];
       }
    },
     addOk(){
       this.$refs.info_form_add.validate( async (valid) => {
        if (valid) {
          if(this.info_form.idCardImg.length!==0){
            this.info_form.idCardImg = this.info_form.idCardImg.map(value => value.url);
          }
          if ( this.info_form.bankCardImg.length!==0){
            this.info_form.bankCardImg = this.info_form.bankCardImg[0].url;
          }

          this.info_form.memberId = this.$route.query.created_by
          await put_memberInfo(this.info_form)
          const data={
            templateId:this.$route.query.template_id,
            memberId:this.$route.query.created_by
          }
          const postId = await post_caseModes(data)
          if (postId.code===0){
            this.info_form.bankCardImg=[]
            this.$message.success('操作成功')
            this.show=true
          }else if (postId.code===-1){
            this.$message.error('该模板已选择')
          }
          await this.getMemberInfo()
        } else {
          this.$message.error('输入有空~')
          return false;
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.CHD_Info{
  margin: 0 auto;
  padding-bottom: 40px;
  .CHD_Info_form{

    ::v-deep .el-input__inner{
      background-color: #f7f8fc;
      border: 1px solid #f7f8fc;
    }

    ::v-deep .el-form-item__label{
      padding: 0 0 5px;
    }
  }

  .CHD_Info_bottom{
    width: 100%;
    left: 0;
    bottom: 0;
    padding-bottom: 15px;
    position: fixed;
    background: white;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    //border-top: #afadad 1px solid;
  }

}
</style>
