<template>
  <div class="CHD_survey">
    <!--    {{questionnaire_info}}-->
    <!--    <h2>比伐芦定在冠心病行PCI治疗患者中应用的真实世界研究   患者id传0</h2>-->

    <div v-if="this.$route.query.isSelect ==='false'">

      <el-collapse v-model="activeName" accordion>
        <el-collapse-item name="1" title="病例报告确认">
          <el-form
              ref="case"
              :model="case_form.caseReport" label-position="top">
            <el-form-item :rules="[{ required: true, message: '请选择一个选项', trigger: 'change' },]"
                          label="PCI围术期使用比伐芦定进行抗凝治疗的冠心病患者"
                          prop="PCI"
            >
              <el-radio-group v-model="case_form.caseReport.PCI">
                <el-radio label="是">是</el-radio>
                <el-radio label="否">否(不符合入组标准)</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="case_form.caseReport.PCI==='是'"
                          :rules="[{ required: true, message: '请选择一个选项', trigger: 'change' },]"
                          label="已签署书面知情同意书"
                          prop="SigForm"
            >
              <el-radio-group v-model="case_form.caseReport.SigForm">
                <el-radio label="是">是</el-radio>
                <el-radio label="否">否(不符合入组标准)</el-radio>
              </el-radio-group>
            </el-form-item>


            <el-form-item v-if="case_form.caseReport.PCI==='是' && case_form.caseReport.SigForm==='是'"
                          :rules="[{ required: true, message: '请选择一个选项', trigger: 'change' },]"
                          label="对比伐芦定及其辅料或水蛭素过敏者"
                          prop="hirudin"
            >
              <el-radio-group v-model="case_form.caseReport.hirudin">
                <el-radio label="是">是(不符合入组标准)</el-radio>
                <el-radio label="否">否</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="case_form.caseReport.PCI==='是' && case_form.caseReport.SigForm==='是'
                        && case_form.caseReport.hirudin==='否'"
                          :rules="[{ required: true, message: '请选择一个选项', trigger: 'change' },]"
                          label="活动性出血者或存在出血性疾病导致出血风险增加，以及不可逆凝血功能障碍患者"
                          prop="activeBleeding"
            >
              <el-radio-group v-model="case_form.caseReport.activeBleeding">
                <el-radio label="是">是(不符合入组标准)</el-radio>
                <el-radio label="否">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="case_form.caseReport.PCI==='是' && case_form.caseReport.SigForm==='是'
                        && case_form.caseReport.hirudin==='否' && case_form.caseReport.activeBleeding==='否' "
                          :rules="[{ required: true, message: '请选择一个选项', trigger: 'change' },]"
                          label="严重的未被控制的高血压"
                          prop="hypertension"
            >
              <el-radio-group v-model="case_form.caseReport.hypertension">
                <el-radio label="是">是(不符合入组标准)</el-radio>
                <el-radio label="否">否</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="case_form.caseReport.PCI==='是' && case_form.caseReport.SigForm==='是'
                        && case_form.caseReport.hirudin==='否' && case_form.caseReport.activeBleeding==='否' &&case_form.caseReport.hypertension==='否'"
                          :rules="[{ required: true, message: '请选择一个选项', trigger: 'change' },]"
                          label="亚急性细菌性心内膜炎"
                          prop="subacute"
            >
              <el-radio-group v-model="case_form.caseReport.subacute">
                <el-radio label="是">是(不符合入组标准)</el-radio>
                <el-radio label="否">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>


        </el-collapse-item>
        <el-collapse-item :disabled="case_form.demographic.show===false" name="2" title="人口学资料">
          <el-form
              ref="case"
              :model="case_form.demographic" label-position="top">
            <el-form-item :rules="[{ required: true, message: '请输入内容 ', trigger: 'blur' },]"

                          prop="fullName"
            >
              <span slot="label" class="isUrinalysis">
                姓名缩写
                <span style="color: #1ea0fa" @click="centerDialogVisible = true">（点击查看【姓名缩写】填写新规定）</span>
              </span>
              <el-input v-model="case_form.demographic.fullName" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item :rules="[{ required: true, message: '请输入内容 ', trigger: 'blur' },]"
                          label="住院号"
                          prop="patientNo"
            >
              <el-input v-model="case_form.demographic.patientNo" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item :rules="[{ required: true, message: '请选择日期', trigger: 'blur' },]"
                          label="出生日期"
                          prop="birth"
            >
              <van-field
                  :value="case_form.demographic.birth"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker_birth = true; showPickerType='birth'"
              />
            </el-form-item>
            <el-form-item :rules="[{ required: true, message: '请选择一个选项', trigger: 'change' },]"
                          label="性别"
                          prop="sex"
            >
              <el-radio-group v-model="case_form.demographic.sex">
                <el-radio label="男">男</el-radio>
                <el-radio label="女">女</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="民族">
              <el-input v-model="case_form.demographic.nation" placeholder="请输入"></el-input>
            </el-form-item>

            <el-form-item :rules="[{ required: true, message: '请输入内容 ', trigger: 'blur' },]"
                          label="体重（Kg）"
                          prop="weight"
            >
              <el-input v-model="case_form.demographic.weight" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="身高（cm）">
              <el-input v-model="case_form.demographic.height" placeholder="请输入"></el-input>
            </el-form-item>
          </el-form>

        </el-collapse-item>
        <el-collapse-item :disabled="case_form.current.show===false" name="3" title="现病史及伴随疾病">
          <el-form
              ref="case"
              :model="case_form.current" label-position="top">
            <el-form-item :rules="[{ required: true, message: '请选择一个选项 ', trigger: 'blur' },]"
                          label="现病史及伴随疾病"
            >
              <van-field
                  v-model="case_form.current.curent.toString()"
                  clickable
                  placeholder="请选择"
                  readonly
                  @click="isShowDialog = true"
              />
              <van-popup
                  v-model="isShowDialog"
                  :style="{ height: '40%' }"
                  closeable
                  position="bottom"
              >
                <el-checkbox-group v-model="case_form.current.curent" style="display: grid;width: 100%;padding: 10px">
                  <el-checkbox label="糖尿病"></el-checkbox>
                  <el-checkbox label="高血压"></el-checkbox>
                  <el-checkbox label="高血脂"></el-checkbox>
                  <el-checkbox label="出血风险"></el-checkbox>
                  <el-checkbox label="冠心病病史"></el-checkbox>
                  <el-checkbox label="既往冠心病家族史"></el-checkbox>
                  <el-checkbox label="外周动脉疾病"></el-checkbox>
                  <el-checkbox label="卒中"></el-checkbox>
                  <el-checkbox label="外科治疗史"></el-checkbox>
                  <el-checkbox label="消化性溃疡史"></el-checkbox>
                  <el-checkbox label="肝病史"></el-checkbox>
                  <el-checkbox label="慢性肾病史"></el-checkbox>
                  <el-checkbox label="计划进行冠状动脉、脑血管或外周动脉血运重建手术" style="display: flex;
align-items: center;"></el-checkbox>
                  <el-checkbox label="有阿司匹林或ADP受体阻滞剂应用禁忌症"></el-checkbox>
                  <el-checkbox label="计划进行心脏或非心脏大手术"></el-checkbox>
                  <el-checkbox label="计划联合使用CYP2C19强效或中效抑制剂"></el-checkbox>
                  <el-checkbox label="颅内血管异常形式"></el-checkbox>
                  <el-checkbox label="中枢神经系统肿瘤"></el-checkbox>
                  <el-checkbox label="颅内出血"></el-checkbox>
                  <el-checkbox label="吸烟史"></el-checkbox>
                  <el-checkbox label="饮酒史"></el-checkbox>
                  <el-checkbox label="计划怀孕或者正在哺乳"></el-checkbox>
                  <el-checkbox label="精神病史"></el-checkbox>
                  <el-checkbox label="严重疾病"></el-checkbox>
                  <el-checkbox label="正在参与其它器械或药物临床试验，且未达到主要终点" style="display: flex;
align-items: center;"></el-checkbox>
                  <el-checkbox label="其他"></el-checkbox>
                  <el-checkbox label="无"></el-checkbox>
                </el-checkbox-group>
              </van-popup>
            </el-form-item>
            <el-form-item v-if="case_form.current.curent.some(r=>r==='糖尿病')"
                          label="糖尿病类型"
                          prop="diabetesType"
            >
              <!--              :rules="[{ required: true, message: '请选择一个选项 ', trigger: 'blur' },]"-->
              <el-radio-group v-model="case_form.current.diabetesType">
                <el-radio label="I型糖尿病">I型糖尿病</el-radio>
                <el-radio label="II型糖尿病">II型糖尿病</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="case_form.current.curent.some(r=>r==='糖尿病')"
                          label="糖尿病治疗方式"
                          prop="diabetesTreatment"

            >
              <!--              :rules="[{ required: true, message: '请选择一个选项 ', trigger: 'blur' },]"-->
              <el-checkbox-group v-model="case_form.current.diabetesTreatment"
                                 style="display: grid;width: 100%;padding: 10px">
                <el-checkbox label="未进行治疗"></el-checkbox>
                <el-checkbox label="饮食控制"></el-checkbox>
                <el-checkbox label="服降糖药"></el-checkbox>
                <el-checkbox label="注射胰岛素"></el-checkbox>
                <el-checkbox label="其他"></el-checkbox>
              </el-checkbox-group>
              <el-input v-if="case_form.current.diabetesTreatment.some(r=>r==='其他')"
                        v-model="case_form.current.diabetesType_other" placeholder="请输入"></el-input>
            </el-form-item>


            <el-form-item v-if="case_form.current.curent.some(r=>r==='卒中')"
                          label="卒中类型"
                          prop="stroke.strokeType"

            >
              <!--              :rules="[{ required: true, message: '请选择一个选项 ', trigger: 'blur' },]"-->
              <el-radio-group v-model="case_form.current.stroke.strokeType">
                <el-radio label="出血型">出血型</el-radio>
                <el-radio label="缺血型">缺血型</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="case_form.current.curent.some(r=>r==='外科治疗史')"
                          label="外科治疗史-手术名称"
                          prop="wkzls.historySurgicalName"

            ><!--                          :rules="[{ required: true, message: '请输入 ', trigger: 'blur' },]"-->
              <el-input v-model="case_form.current.wkzls.historySurgicalName" placeholder="请输入"></el-input>
            </el-form-item>

            <el-form-item v-if="case_form.current.curent.some(r=>r==='外科治疗史')"
                          label="外科治疗史-手术日期"

            >
              <!--                          :rules="[{ required: true, message: '请选择一个选项 ', trigger: 'blur' },]"-->
              <van-field
                  :value="case_form.current.wkzls.historySurgicalDate"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='historySurgicalDate'"
              />

            </el-form-item>

            <el-form-item v-if="case_form.current.curent.some(r=>r==='肝病史')"
                          label="肝功能损伤"
                          prop="gbs.liverDamage"

            >
              <!--                          :rules="[{ required: true, message: '请选择一个选项 ', trigger: 'blur' },]"-->
              <el-radio-group v-model="case_form.current.gbs.liverDamage">
                <el-radio label="轻度">轻度</el-radio>
                <el-radio label="中重度">中重度</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="case_form.current.curent.some(r=>r==='慢性肾病史')"
                          label="慢性肾病史采取的治疗措施"
                          prop="mxsbs.tmfcd"

            >
              <!--              :rules="[{ required: true, message: '请选择一个选项 ', trigger: 'blur' },]"-->
              <el-radio-group v-model="case_form.current.mxsbs.tmfcd">
                <el-radio label="药物">药物</el-radio>
                <el-radio label="透析">透析</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="case_form.current.curent.some(r=>r==='计划进行冠状动脉、脑血管或外周动脉血运重建手术')"
                          label="计划进行冠状动脉、脑血管或外周动脉血运重建手术-手术名称"
                          prop="planToSurgery.surgeryName"

            >
              <el-input v-model="case_form.current.planToSurgery.surgeryName" placeholder="请输入"></el-input>
            </el-form-item>

            <el-form-item v-if="case_form.current.curent.some(r=>r==='计划进行心脏或非心脏大手术')"
                          label="计划进行心脏或非心脏大手术-手术名称"
                          prop="majorSurgery.surgeryName"

            >
              <el-input v-model="case_form.current.majorSurgery.surgeryName" placeholder="请输入"></el-input>

            </el-form-item>

            <el-form-item v-if="case_form.current.curent.some(r=>r==='颅内血管异常')"
                          label="颅内血管异常形式"
                          prop="bloodVessels.bloodVesselsType"
            >
              <el-radio-group v-model="case_form.current.bloodVessels.bloodVesselsType">
                <el-radio label="动脉瘤">动脉瘤</el-radio>
                <el-radio label="动静脉畸形">动静脉畸形</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="case_form.current.curent.some(r=>r==='吸烟史')"
                          label="吸烟年限"
                          prop="smokes.smokeYear"

            >
              <el-input v-model="case_form.current.smokes.smokeYear" placeholder="请输入"></el-input>
            </el-form-item>

            <el-form-item v-if="case_form.current.curent.some(r=>r==='吸烟史')"
                          label="是否已戒烟"
                          prop="smokes.isSmoke"
            >
              <el-radio-group v-model="case_form.current.smokes.isSmoke">
                <el-radio label="是">是</el-radio>
                <el-radio label="否">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="case_form.current.smokes.isSmoke==='是'"
                          label="吸烟年限"
                          prop="smokes.smokeYearDie"
            >
              <el-input v-model="case_form.current.smokes.smokeYearDie" placeholder="请输入"></el-input>
            </el-form-item>

            <el-form-item v-if="case_form.current.curent.some(r=>r==='饮酒史')"
                          label="是否已戒酒"
                          prop="beers.isBeer"
            >
              <el-radio-group v-model="case_form.current.beers.isBeer">
                <el-radio label="是">是</el-radio>
                <el-radio label="否">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="case_form.current.curent.some(r=>r==='严重疾病')"
                          label="严重疾病预计生存期"
                          prop="yzjb.severeDiseases"
            >
              <el-radio-group v-model="case_form.current.yzjb.severeDiseases">
                <el-radio label="<1年"><1年</el-radio>
                <el-radio label="≥1年">≥1年</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </el-collapse-item>
        <el-collapse-item :disabled="case_form.intervene.show===false" name="4" title="冠脉介入操作">
          <el-form
              ref="case"
              :model="case_form.intervene" label-position="top">
            <el-form-item :rules="[{ required: true, message: '请选择日期', trigger: 'blur' },]"
                          label="手术日期"
                          prop="firstSurgeryDate"
            >
              <!--            2023年5月1号前不能选   第二次日期大于第一次-->
              <van-field
                  :value="case_form.intervene.firstSurgeryDate"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker_firstSurgeryDate = true; showPickerType='firstSurgeryDate'"
              />


            </el-form-item>

            <el-form-item
                label="血管"
                prop="bloodVessel"
            >
              <el-radio-group v-model="case_form.intervene.bloodVessel" style="display: grid">
                <el-radio label="LAD">LAD</el-radio>
                <el-radio label="LCX">LCX</el-radio>
                <el-radio label="LM">LM</el-radio>
                <el-radio label="RCA">RCA</el-radio>
                <el-radio label="其他">其他</el-radio>
              </el-radio-group>
              <el-input v-if="case_form.intervene.bloodVessel==='其他'" v-model="case_form.intervene.bloodVessel_other"
                        placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item
                label="支架类型"
                prop="supportType"
            >
              <el-radio-group v-model="case_form.intervene.supportType">
                <el-radio label="BMS">BMS</el-radio>
                <el-radio label="DES">DES</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item
                label="支架数量"
                prop="supportNum"
            >
              <el-input v-model="case_form.intervene.supportNum" placeholder="请输入"></el-input>
            </el-form-item>

            <el-form-item :rules="[{ required: true, message: '请选择一个选项', trigger: 'change' },]"
                          label="是否进行再次手术"
                          prop="isSecondSurgery.isSecondSurgery"
            >
              <el-radio-group v-model="case_form.intervene.isSecondSurgery.isSecondSurgery">
                <el-radio label="是">是</el-radio>
                <el-radio label="否">否</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="case_form.intervene.isSecondSurgery.isSecondSurgery==='是'"
                          label="二次手术原因"
                          prop="isSecondSurgery.secondSurgeryReason"

            >
              <el-input v-model="case_form.intervene.isSecondSurgery.secondSurgeryReason"
                        placeholder="请输入"></el-input>
            </el-form-item>

            <el-form-item v-if="case_form.intervene.isSecondSurgery.isSecondSurgery==='是'"
                          label="第二次手术日期"
                          prop="isSecondSurgery.secondSurgeryDate"

            >
              <van-field
                  :value="case_form.intervene.isSecondSurgery.secondSurgeryDate"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker_firstSurgeryDate = true; showPickerType='secondSurgeryDate'"
              />

            </el-form-item>

            <el-form-item v-if="case_form.intervene.isSecondSurgery.isSecondSurgery==='是'"
                          label="第二次手术血管"
                          prop="isSecondSurgery.secondSurgeryBloodVessel"

            >
              <el-radio-group v-model="case_form.intervene.isSecondSurgery.secondSurgeryBloodVessel"
                              style="display: grid">
                <el-radio label="LAD">LAD</el-radio>
                <el-radio label="LCX">LCX</el-radio>
                <el-radio label="LM">LM</el-radio>
                <el-radio label="RCA">RCA</el-radio>
                <el-radio label="其他">其他</el-radio>
              </el-radio-group>
              <el-input v-if="case_form.intervene.isSecondSurgery.secondSurgeryBloodVessel==='其他'"
                        v-model="case_form.intervene.isSecondSurgery.bloodVessel_other" placeholder="请输入"/>
            </el-form-item>
            <el-form-item v-if="case_form.intervene.isSecondSurgery.isSecondSurgery==='是'"
                          label="第二次手术支架类型"
                          prop="isSecondSurgery.secondSurgerySupportType"

            >
              <el-radio-group v-model="case_form.intervene.isSecondSurgery.secondSurgerySupportType">
                <el-radio label="BMS">BMS</el-radio>
                <el-radio label="DES">DES</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="case_form.intervene.isSecondSurgery.isSecondSurgery==='是'"
                          label="第二次手术支架数量"
                          prop="isSecondSurgery.secondSurgerySupportNum"

            >
              <el-input v-model="case_form.intervene.isSecondSurgery.secondSurgerySupportNum" placeholder="请输入"/>
            </el-form-item>
          </el-form>

        </el-collapse-item>
        <el-collapse-item :disabled="case_form.vitalSigns.show===false" name="5" title="生命体征">
          <el-form
              ref="case"
              :model="case_form.vitalSigns" label-position="top">
            <!--            <el-form-item :rules="[{ required: true, message: '请选择一个选项', trigger: 'change' },]"-->
            <!--                          label="是否检查生命体征"-->
            <!--                          prop="isVital"-->
            <!--            >-->
            <!--              <el-radio-group v-model="case_form.vitalSigns.isVital">-->
            <!--                <el-radio label="是">是</el-radio>-->
            <!--                <el-radio label="否">否</el-radio>-->
            <!--              </el-radio-group>-->
            <!--            </el-form-item>-->

            <el-form-item
                :rules="[{ required: true, message: '请选择日期', trigger: 'blur' },]"
                label="检查日期"
                prop="checkDate"
            >
              <van-field
                  :value="case_form.vitalSigns.checkDate"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='checkDate'"
              />

            </el-form-item>
            <el-form-item
                :rules="[{ required: true, message: '请输入', trigger: 'blur' },]"
                label="血压-收缩压(mmHg)"
                prop="xy.bloodPressure"

            >
              <el-input v-model="case_form.vitalSigns.xy.bloodPressure" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item
                :rules="[{ required: true, message: '请输入', trigger: 'blur' },]"
                label="血压-舒张压(mmHg)"
                prop="xy.bloodPressureDiastolic"
            >
              <el-input v-model="case_form.vitalSigns.xy.bloodPressureDiastolic" placeholder="请输入"></el-input>
            </el-form-item>

            <el-form-item
                label="检查结果"
                prop="results.checkResults"
            >
              <el-radio-group v-model="case_form.vitalSigns.results.checkResults">
                <el-radio label="正常">正常</el-radio>
                <el-radio label="异常">异常</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="case_form.vitalSigns.results.checkResults==='异常'"
                          label="异常描述"
                          prop="results.exception"
            >
              <el-input v-model="case_form.vitalSigns.results.exception" placeholder="请输入"></el-input>
            </el-form-item>
          </el-form>
        </el-collapse-item>
        <el-collapse-item :disabled="case_form.leadEcg.show===false" name="6" title="导联心电图(ECG)">
          <el-form
              ref="case"
              :model="case_form.leadEcg" label-position="top">
            <el-form-item :rules="[{ required: true, message: '请选择一个选项', trigger: 'change' },]"
                          label="是否检查导联心电图(ECG)"
                          prop="isEcg"
            >
              <el-radio-group v-model="case_form.leadEcg.isEcg">
                <el-radio label="是">是</el-radio>
                <el-radio label="否">否</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="case_form.leadEcg.isEcg==='是'"
                          label="检查日期"
                          prop="checkDate"
            >
              <van-field
                  :value="case_form.leadEcg.checkDate"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='isEcg'"
              />

            </el-form-item>
            <el-form-item v-if="case_form.leadEcg.isEcg==='是'"
                          :rules="[{ required: true, message: '请输入', trigger: 'blur' },]"
                          label="心率(次/分)"
                          prop="xl"
            >
              <el-input v-model="case_form.leadEcg.xl" placeholder="请输入"></el-input>
            </el-form-item>

            <el-form-item v-if="case_form.leadEcg.isEcg==='是'"
                          label="检查结果"
                          prop="result.checkResults"
            >
              <el-radio-group v-model="case_form.leadEcg.result.checkResults">
                <el-radio label="正常">正常</el-radio>
                <el-radio label="异常">异常</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="case_form.leadEcg.result.checkResults==='异常'"
                          label="异常描述"
                          prop="result.exception"
            >
              <el-input v-model="case_form.leadEcg.result.exception" placeholder="请输入"></el-input>
            </el-form-item>


          </el-form>

        </el-collapse-item>
        <el-collapse-item :disabled="case_form.echo.show===false" name="7" title="超声心动图">
          <el-form
              ref="case"
              :model="case_form.echo" label-position="top">
            <el-form-item :rules="[{ required: true, message: '请选择一个选项', trigger: 'change' },]"
                          label="是否检查超声心动图"
                          prop="isEcho"
            >
              <el-radio-group v-model="case_form.echo.isEcho">
                <el-radio label="是">是</el-radio>
                <el-radio label="否">否</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="case_form.echo.isEcho==='是'"
                          label="检查日期"
                          prop="checkDate"
            >
              <van-field
                  :value="case_form.echo.checkDate"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='echo'"
              />
            </el-form-item>
            <!--            <el-form-item v-if="case_form.echo.isEcho==='是'"-->
            <!--                          label="左室舒张末期内径(LVEDD)(单位:mm)"-->
            <!--                          prop="leftLVEDD"-->
            <!--            >-->
            <!--              <el-input v-model="case_form.echo.leftLVEDD" placeholder="请输入"></el-input>-->

            <!--            </el-form-item>-->

            <el-form-item v-if="case_form.echo.isEcho==='是'"
                          :rules="[{ required: true, message: '请输入', trigger: 'blur' },]"
                          label="射血分数(EF)(单位:%)"
                          prop="bloodEF"
            >
              <el-input v-model="case_form.echo.bloodEF" placeholder="请输入"></el-input>
            </el-form-item>

            <!--            <el-form-item v-if="case_form.echo.isEcho==='是'"-->
            <!--                          label="E峰与A峰比值E/A"-->
            <!--                          prop="ECompareA"-->
            <!--            >-->
            <!--              <el-input v-model="case_form.echo.ECompareA" placeholder="请输入"></el-input>-->
            <!--            </el-form-item>-->
          </el-form>

        </el-collapse-item>
        <el-collapse-item :disabled="case_form.bloodRoutine.show===false" name="8" title="血常规">
          <el-form
              ref="case"
              :model="case_form.bloodRoutine" label-position="top">
            <!--            <el-form-item :rules="[{ required: true, message: '请选择一个选项', trigger: 'change' },]"-->
            <!--                          label="是否检查血常规"-->
            <!--                          prop="isBloodRoutine"-->
            <!--            >-->
            <!--              <el-radio-group v-model="case_form.bloodRoutine.isBloodRoutine">-->
            <!--                <el-radio label="是">是</el-radio>-->
            <!--                <el-radio label="否">否</el-radio>-->
            <!--              </el-radio-group>-->
            <!--            </el-form-item>-->

            <el-form-item
                label="检查日期"
                prop="checkDate"
            >
              <van-field
                  :value="case_form.bloodRoutine.checkDate"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='bloodRoutine'"
              />

            </el-form-item>
            <el-form-item
                :rules="[{ required: true, message: '请输入', trigger: 'blur' },]"
                label="白细胞总数（WBC）（单位：×10⁹/L）"
                prop="WBCNum.result"
            >
              <el-input v-model="case_form.bloodRoutine.WBCNum.result" placeholder="请输入"></el-input>

            </el-form-item>
            <el-form-item
                :rules="[{ required: true, message: '请输入', trigger: 'blur' },]"
                label="中性粒细胞计数（NEUT）（单位：×10⁹/L）"
                prop="NEUTNum.result"
            >
              <el-input v-model="case_form.bloodRoutine.NEUTNum.result" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item v-if="case_form.bloodRoutine.isBloodRoutine==='是'"
                          label="血红蛋白（Hb）（单位：g/L）"
                          prop="HB.result"

            >
              <el-input v-model="case_form.bloodRoutine.HB.result" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item
                label="红细胞总数（RBC）（单位：×10¹²/L）"
                prop="RBCNum.result"

            >
              <el-input v-model="case_form.bloodRoutine.RBCNum.result" placeholder="请输入"></el-input>

            </el-form-item>
            <el-form-item
                label="血小板（PLT）（单位：×10⁹/L）"
                prop="PLT.result"

            >
              <el-input v-model="case_form.bloodRoutine.PLT.result" placeholder="请输入"></el-input>

            </el-form-item>

          </el-form>

        </el-collapse-item>
        <el-collapse-item :disabled="case_form.urinalysis.show===false" name="9" title="尿常规">
          <el-form
              ref="case"
              :model="case_form.urinalysis" label-position="top">
            <!--            <el-form-item :rules="[{ required: true, message: '请选择一个选项', trigger: 'change' },]"-->
            <!--                          label="是否检查尿常规"-->
            <!--                          prop="isUrinalysis"-->
            <!--            >-->
            <!--              <el-radio-group v-model="case_form.urinalysis.isUrinalysis">-->
            <!--                <el-radio label="是">是</el-radio>-->
            <!--                <el-radio label="否">否</el-radio>-->
            <!--              </el-radio-group>-->
            <!--            </el-form-item>-->

            <el-form-item
                :rules="[{ required: true, message: '请选择日期', trigger: 'blur' },]"
                label="检查日期"
                prop="checkDate"
            >
              <van-field
                  :value="case_form.urinalysis.checkDate"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='urinalysis'"
              />

            </el-form-item>
            <el-form-item
                prop="urineProtein.result"
            >
              <span slot="label" class="isUrinalysis">
                尿蛋白定性(
                <el-input v-model="case_form.urinalysis.urineProtein.unit" placeholder="自行填写单位"
                          size="mini" style="width: 35%;background: white"></el-input>)
              </span>
              <el-input v-model="case_form.urinalysis.urineProtein.result" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item
                prop="urineSugar.result"
            >
              <span slot="label" class="isUrinalysis">
                尿糖(
                <el-input v-model="case_form.urinalysis.urineSugar.unit" placeholder="自行填写单位"
                          size="mini" style="width: 40%;background: white"></el-input>)
              </span>
              <el-input v-model="case_form.urinalysis.urineSugar.result" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item
                prop="urineBlood.result"
            >
              <span slot="label" class="isUrinalysis">
                尿隐血(
                <el-input v-model="case_form.urinalysis.urineBlood.unit" placeholder="自行填写单位"
                          size="mini" style="width: 40%;background: white"></el-input>)
              </span>
              <el-input v-model="case_form.urinalysis.urineBlood.result" placeholder="请输入"></el-input>

            </el-form-item>

          </el-form>

        </el-collapse-item>
        <el-collapse-item :disabled="case_form.bloodGly.show===false" name="10" title="血生化+糖化血红蛋白">
          <el-form
              ref="case"
              :model="case_form.bloodGly" label-position="top">
            <el-form-item :rules="[{ required: true, message: '请选择一个选项', trigger: 'change' },]"
                          label="是否检查血生化+糖化血红蛋白"
                          prop="isBloodGly"
            >
              <el-radio-group v-model="case_form.bloodGly.isBloodGly">
                <el-radio label="是">是</el-radio>
                <el-radio label="否">否</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="case_form.bloodGly.isBloodGly==='是'"
                          label="检查日期"
                          prop="checkDate"
            >
              <van-field
                  :value="case_form.bloodGly.checkDate"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='bloodGly'"
              />
            </el-form-item>
            <el-form-item v-if="case_form.bloodGly.isBloodGly==='是'"
                          :rules="[{ required: true, message: '请输入', trigger: 'blur' },]"
                          label="谷丙转氨酶（ALT/GPT）（单位：U/L）"
                          prop="ALT.result"
            >
              <el-input v-model="case_form.bloodGly.ALT.result" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item v-if="case_form.bloodGly.isBloodGly==='是'"
                          :rules="[{ required: true, message: '请输入', trigger: 'blur' },]"
                          label="谷草转氨酶（AST/GOT）（单位：U/L）"
                          prop="AST.result"
            >
              <el-input v-model="case_form.bloodGly.AST.result" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item v-if="case_form.bloodGly.isBloodGly==='是'"
                          :rules="[{ required: true, message: '请输入', trigger: 'blur' },]"
                          label="总胆固醇（TC）（mmol/L）"
                          prop="TC.result"
            >
              <el-input v-model="case_form.bloodGly.TC.result" placeholder="请输入"></el-input>

            </el-form-item>
            <el-form-item v-if="case_form.bloodGly.isBloodGly==='是'"
                          :rules="[{ required: true, message: '请输入', trigger: 'blur' },]"
                          label="甘油三脂（TG）（单位：mmol/L）"
                          prop="TG.result"
            >
              <el-input v-model="case_form.bloodGly.TG.result" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item v-if="case_form.bloodGly.isBloodGly==='是'"
                          label="低密度脂蛋白（LDL-C）（单位：mmol/L）"
                          prop="LDL.result"

            >
              <el-input v-model="case_form.bloodGly.LDL.result" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item v-if="case_form.bloodGly.isBloodGly==='是'"
                          label="高密度脂蛋白（HDL-C）（单位：mmol/L）"
                          prop="HDL.result"

            >
              <el-input v-model="case_form.bloodGly.HDL.result" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item v-if="case_form.bloodGly.isBloodGly==='是'"
                          label="空腹血糖（单位：mmol/L）"
                          prop="bloodSugar.result"

            >
              <el-input v-model="case_form.bloodGly.bloodSugar.result" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item v-if="case_form.bloodGly.isBloodGly==='是'"
                          label="肌酐（Cr）"
                          prop="CR.result"

            >
              <el-input v-model="case_form.bloodGly.CR.result" placeholder="请输入"></el-input>
            </el-form-item>

            <el-form-item v-if="case_form.bloodGly.isBloodGly==='是'"
                          label="肌酐（Cr）单位"
                          prop="CR.unit"

            >
              <el-radio-group v-model="case_form.bloodGly.CR.unit">
                <el-radio label="μmol/L">μmol/L</el-radio>
                <el-radio label="mg/dl">mg/dl</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="case_form.bloodGly.isBloodGly==='是'"
                          label="血尿酸（UA）（单位：μmol/L）"
                          prop="UA.result"
            >
              <el-input v-model="case_form.bloodGly.UA.result" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item v-if="case_form.bloodGly.isBloodGly==='是'"
                          label="钾（K+）（单位：mmol/L）"
                          prop="K+.result"

            >
              <el-input v-model="case_form.bloodGly['K+'].result" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item v-if="case_form.bloodGly.isBloodGly==='是'"
                          label="糖化血红蛋白（HbA1c）（单位：%）"
                          prop="HBA1C.result"

            >
              <el-input v-model="case_form.bloodGly.HBA1C.result" placeholder="请输入"></el-input>
            </el-form-item>
          </el-form>

        </el-collapse-item>
        <el-collapse-item :disabled="case_form.drugStatus.show===false" name="11" title="入组抗栓药物用药情况">
          <el-form
              ref="case"
              :model="case_form.drugStatus"
              :rules="rules" label-position="top">
            <el-form-item :rules="[{ required: true, message: '请选择一个选项', trigger: 'change' },]"
                          label="抗凝药物是否使用比伐芦定"
                          prop="doesUse"
            >
              <el-radio-group v-model="case_form.drugStatus.doesUse">
                <el-radio label="是">是 (总剂量mg)</el-radio>
                <el-radio label="否">否 (不符合入组条件)</el-radio>
              </el-radio-group>

            </el-form-item>
            <el-form-item
                v-if="case_form.drugStatus.doesUse==='是'"
                prop="doesUses">
              <span style="color: red">*</span>
              <el-input v-model.number="case_form.drugStatus.doesUses" controls-position="right"
                        placeholder="总剂量不能少于500mg" style="margin-top: 5px;width: 95%"></el-input>

            </el-form-item>
            <el-form-item
                label="其他抗凝药物使用"
                prop="otherUse"
            >
              <el-checkbox-group v-model="case_form.drugStatus.otherUse" style="display: grid">
                <el-checkbox label="低分子肝素">低分子肝素 (每日剂量IU)</el-checkbox>
                <el-input v-if="case_form.drugStatus.otherUse.some(r=>r==='低分子肝素')"
                          v-model="case_form.drugStatus.otherUse01"
                          placeholder="请输入" style="margin-top: 5px"/>
                <el-checkbox label="大分子肝素续灌">大分子肝素续灌 (每日剂量IU)</el-checkbox>
                <el-input v-if="case_form.drugStatus.otherUse.some(r=>r==='大分子肝素续灌')"
                          v-model="case_form.drugStatus.otherUse02"
                          placeholder="请输入" style="margin-top: 5px"></el-input>
              </el-checkbox-group>
            </el-form-item>

            <el-form-item
                label="血小板环氧化酶抑制药"
                prop="platelet"
            >
              <el-radio v-model="case_form.drugStatus.platelet" label="阿司匹林">阿司匹林 (每日剂量mg)</el-radio>
              <el-input v-if="case_form.drugStatus.platelet==='阿司匹林'" v-model="case_form.drugStatus.platelet01"
                        placeholder="请输入" style="margin-top: 5px"></el-input>
            </el-form-item>


            <el-form-item label="ADP受体拮抗剂"
            >
              <el-checkbox-group v-model="case_form.drugStatus.ADP" style="display: grid">
                <el-checkbox label="氯吡格雷">氯吡格雷 (每日剂量mg)</el-checkbox>
                <el-input v-if="case_form.drugStatus.ADP.some(r=>r==='氯吡格雷')" v-model="case_form.drugStatus.ADP01"
                          placeholder="请输入" style="margin-top: 5px"/>
                <el-checkbox label="替格瑞洛">替格瑞洛 (每日剂量mg)</el-checkbox>
                <el-input v-if="case_form.drugStatus.ADP.some(r=>r==='替格瑞洛')" v-model="case_form.drugStatus.ADP02"
                          placeholder="请输入" style="margin-top: 5px"/>
                <el-checkbox label="其他ADP受体拮抗剂"></el-checkbox>
                <el-input v-if="case_form.drugStatus.ADP.some(r=>r==='其他ADP受体拮抗剂')"
                          v-model="case_form.drugStatus.ADP03"
                          placeholder="请输入" style="margin-top: 5px"/>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="磷酸二酯酶抑制药"
            >
              <el-checkbox-group v-model="case_form.drugStatus.phospate" style="display: grid">
                <el-checkbox label="双嘧达莫">双嘧达莫 (每日剂量mg)</el-checkbox>
                <el-input v-if="case_form.drugStatus.phospate.some(r=>r==='双嘧达莫')"
                          v-model="case_form.drugStatus.phospate02"
                          placeholder="请输入" style="margin-top: 5px"/>
                <el-checkbox label="西洛他唑">西洛他唑 (每日剂量mg)</el-checkbox>
                <el-input v-if="case_form.drugStatus.phospate.some(r=>r==='西洛他唑')"
                          v-model="case_form.drugStatus.phospate03"
                          placeholder="请输入" style="margin-top: 5px"/>
              </el-checkbox-group>
            </el-form-item>
          </el-form>

        </el-collapse-item>
        <el-collapse-item :disabled="case_form.ACT.show===false" name="12" title="检查ACT">
          <el-form
              ref="case"
              :model="case_form.ACT" label-position="top">
            <el-form-item :rules="[{ required: true, message: '请选择一个选项', trigger: 'change' },]"
                          label="是否检查ACT"
                          prop="isACT"
            >
              <el-radio-group v-model="case_form.ACT.isACT">
                <el-radio label="是">是</el-radio>
                <el-radio label="否">否</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="case_form.ACT.isACT==='是'"
                          label="检查日期"
                          prop="checkDate"

            >
              <van-field
                  :value="case_form.ACT.checkDate"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='ACT'"
              />

            </el-form-item>
            <el-form-item v-if="case_form.ACT.isACT==='是'"
                          label="术中（单位：S）"
                          prop="intra.result"

            >
              <el-input v-model="case_form.ACT.intra.result" placeholder="请输入"/>
            </el-form-item>
            <el-form-item v-if="case_form.ACT.isACT==='是'"
                          label="术后2小时（单位：S）"
                          prop="intraTwoHours.result"

            >
              <el-input v-model="case_form.ACT.intraTwoHours.result" placeholder="请输入"/>
            </el-form-item>
            <el-form-item v-if="case_form.ACT.isACT==='是'"
                          label="术后6小时（单位：S）"
                          prop="intraSixHours.result"

            >
              <el-input v-model="case_form.ACT.intraSixHours.result" placeholder="请输入"/>
            </el-form-item>
            <el-form-item v-if="case_form.ACT.isACT==='是'"
                          label="术后8小时（单位：S）"
                          prop="intraEightHours.result"

            >
              <el-input v-model="case_form.ACT.intraEightHours.result" placeholder="请输入"/>
            </el-form-item>
            <el-form-item v-if="case_form.ACT.isACT==='是'"
                          label="术后10小时（单位：S）"
                          prop="intraTenHours.result"

            >
              <el-input v-model="case_form.ACT.intraTenHours.result" placeholder="请输入"/>
            </el-form-item>
            <el-form-item v-if="case_form.ACT.isACT==='是'"
                          label="其他"
                          prop="other.result"

            >
              <el-input v-model="case_form.ACT.other.result" placeholder="请输入"/>
            </el-form-item>
          </el-form>

        </el-collapse-item>
        <el-collapse-item :disabled="case_form.followUP.show===false" name="13" title="随访信息">
          <el-form
              ref="case"
              :model="case_form.followUP" label-position="top">
            <el-form-item :rules="[{ required: true, message: '请选择一个选项', trigger: 'change' },]"
                          label="随访方式"
                          prop="followMethod"
            >
              <el-radio-group v-model="case_form.followUP.followMethod" style="display: grid">
                <el-radio label="未进行随访">未进行随访</el-radio>
                <el-radio label="门诊">门诊</el-radio>
                <el-radio label="电话随访">电话随访</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="case_form.followUP.followMethod==='未进行随访'"
                          label="未进行随访原因"
                          prop="remark"

            >
              <el-input v-model="case_form.followUP.remark" placeholder="请输入"/>
            </el-form-item>

            <el-form-item v-else
                          label="随访日期"
                          prop="checkDate"

            >
              <van-field
                  :value="case_form.followUP.followDate"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='followDate'"
              />

            </el-form-item>

          </el-form>

        </el-collapse-item>
        <el-collapse-item :disabled="case_form.clinicalEvents.show===false" name="14" title="临床事件">
          <el-form
              ref="case"
              :model="case_form.clinicalEvents" label-position="top">
            <el-form-item :rules="[{ required: true, message: '请选择一个选项', trigger: 'change' },]"
                          label="是否发生方案偏离"
                          prop="isFapl.fapl"
            >
              <el-radio-group v-model="case_form.clinicalEvents.isFapl.fapl">
                <el-radio label="是">是</el-radio>
                <el-radio label="否">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="case_form.clinicalEvents.isFapl.fapl==='是'"
                          label="偏离编号"

            >
              <el-input v-model="case_form.clinicalEvents.isFapl.faplTable.plNo" placeholder="请输入"/>
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isFapl.fapl==='是'"

                          label="偏离日期"

            >
              <van-field
                  :value="case_form.clinicalEvents.isFapl.faplTable.plDate"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='plDate'"
              />
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isFapl.fapl==='是'"
                          label="偏离发生于"
            >
              <el-radio-group v-model="case_form.clinicalEvents.isFapl.faplTable.plIN" style="display:grid;">
                <el-radio label="筛选/基线"></el-radio>
                <el-radio label="随机化"></el-radio>
                <el-radio label="1个月"></el-radio>
                <el-radio label="3个月"></el-radio>
                <el-radio label="6个月"></el-radio>
                <el-radio label="9个月"></el-radio>
                <el-radio label="12个月"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="case_form.clinicalEvents.isFapl.fapl==='是'"
                          label="偏离程度"
            >
              <el-radio-group v-model="case_form.clinicalEvents.isFapl.faplTable.plcd" style="display: grid">
                <el-radio label="轻"></el-radio>
                <el-radio label="中"></el-radio>
                <el-radio label="重"></el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isFapl.fapl==='是'"
                          label="偏离描述"
            >
              <el-checkbox-group v-model="case_form.clinicalEvents.isFapl.faplTable.plms" style="display: grid">
                <el-checkbox label="人选受试者不符合人选标准/符合排除标淮"></el-checkbox>
                <el-checkbox label="与知情同意相关的方案偏离"></el-checkbox>
                <el-checkbox label="由未授权医师执行程序"></el-checkbox>
                <el-checkbox label="未执行访视/随访"></el-checkbox>
                <el-checkbox label="商口期外访视随访"></el-checkbox>
                <el-checkbox label="未执行/未完成评估/程序"></el-checkbox>
                <el-checkbox label="未在窗口期内评估/程序"></el-checkbox>
                <el-checkbox label="未根据方案使用药物"></el-checkbox>
                <el-checkbox label="其他,请详细说明"></el-checkbox>
              </el-checkbox-group>
              <el-input v-if="case_form.clinicalEvents.isFapl.faplTable.plms.some(r=>r==='其他,请详细说明')"
                        v-model="case_form.clinicalEvents.isFapl.faplTable.plms_other" placeholder="请输入"></el-input>
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isFapl.fapl==='是'"
                          label="偏离描述"
            >
              <el-checkbox-group v-model="case_form.clinicalEvents.isFapl.faplTable.cxplyy" style="display: grid;">
                <el-checkbox label="研究中心错误"></el-checkbox>
                <el-checkbox label="计划安排刚难"></el-checkbox>
                <el-checkbox label="患者不依从"></el-checkbox>
                <el-checkbox label="患者无法按受访视"></el-checkbox>
                <el-checkbox label="患者紧急情况"></el-checkbox>
                <el-checkbox label="其他,请详细说明"></el-checkbox>
              </el-checkbox-group>
              <el-input v-if="case_form.clinicalEvents.isFapl.faplTable.cxplyy.some(r=>r==='其他,请详细说明')"
                        v-model="case_form.clinicalEvents.isFapl.faplTable.cxplyy_other"
                        placeholder="请输入"></el-input>
            </el-form-item>

            <el-form-item :rules="[{ required: true, message: '请选择一个选项', trigger: 'change' },]"
                          label="是否发生不良事件/严重不良事件"
                          prop="isAdverse.adverse"
            >
              <el-radio-group v-model="case_form.clinicalEvents.isAdverse.adverse">
                <el-radio label="是">是</el-radio>
                <el-radio label="否">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="case_form.clinicalEvents.isAdverse.adverse==='是'"
                          label="不良事件编号"
            >
              <el-input v-model="case_form.clinicalEvents.isAdverse.adverseTable.adverseNo" placeholder="请输入"/>
            </el-form-item>
            <el-form-item v-if="case_form.clinicalEvents.isAdverse.adverse==='是'"
                          label="不良事件名称"
            >
              <el-input v-model="case_form.clinicalEvents.isAdverse.adverseTable.adverseName" placeholder="请输入"/>
            </el-form-item>
            <el-form-item v-if="case_form.clinicalEvents.isAdverse.adverse==='是'"
                          label="不良事件描述"
            >
              <el-input v-model="case_form.clinicalEvents.isAdverse.adverseTable.adverseDescribe" placeholder="请输入"/>
            </el-form-item>
            <el-form-item v-if="case_form.clinicalEvents.isAdverse.adverse==='是'"

                          label="事件发生日期"

            >
              <van-field
                  :value="case_form.clinicalEvents.isAdverse.adverseTable.eventDate"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='eventDate'"
              />
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isAdverse.adverse==='是'"

                          label="时间结束日期"

            >
              <van-field
                  :value="case_form.clinicalEvents.isAdverse.adverseTable.eventDieDate"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='eventDieDate'"
              />
            </el-form-item>
            <el-form-item v-if="case_form.clinicalEvents.isAdverse.adverse==='是'"
                          label="不良事件分级"


            >
              <el-radio-group v-model="case_form.clinicalEvents.isAdverse.adverseTable.eventGrade"
                              style="display: grid">
                <el-radio label="轻度">轻度</el-radio>
                <el-radio label="中度">中度</el-radio>
                <el-radio label="重度">重度</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isAdverse.adverse==='是'"

                          label="与试验药物的关系"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isAdverse.adverseTable.withDrug" style="display: grid">
                <el-radio label="肯定有关">肯定有关</el-radio>
                <el-radio label="很可能有关">很可能有关</el-radio>
                <el-radio label="可能有关">可能有关</el-radio>
                <el-radio label="可能无关">可能无关</el-radio>
                <el-radio label="肯定无关 ">肯定无关</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="case_form.clinicalEvents.isAdverse.adverse==='是'"

                          label="与手术/治疗的关系"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isAdverse.adverseTable.withSurgery"
                              style="display: grid">
                <el-radio label="肯定有关">肯定有关</el-radio>
                <el-radio label="很可能有关">很可能有关</el-radio>
                <el-radio label="可能有关">可能有关</el-radio>
                <el-radio label="可能无关">可能无关</el-radio>
                <el-radio label="肯定无关 ">肯定无关</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="case_form.clinicalEvents.isAdverse.adverse==='是'"

                          label="对不良事件采取的措施"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isAdverse.adverseTable.event.eventTaken">
                <el-radio label="有">有</el-radio>
                <el-radio label="无">无</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="case_form.clinicalEvents.isAdverse.adverseTable.event.eventTaken==='有'"
                          label="对不良事件采取的措施的记录"


            >
              <el-input v-model="case_form.clinicalEvents.isAdverse.adverseTable.event.eventLog" placeholder="请输入"/>
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isAdverse.adverse==='是'"
                          label="不良事件转归"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isAdverse.adverseTable.outCome" style="display: grid">
                <el-radio label="症状消失">症状消失</el-radio>
                <el-radio label="症状持续">症状持续</el-radio>
                <el-radio label="死亡">死亡</el-radio>
                <el-radio label="症状缓减">症状缓减</el-radio>
                <el-radio label="不详">不详</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isAdverse.adverse==='是'"

                          label="因不良事件而退出试验"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isAdverse.adverseTable.isExitInEvent">
                <el-radio label="是">是</el-radio>
                <el-radio label="否">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="case_form.clinicalEvents.isAdverse.adverse==='是'"

                          label="是否为严重不良事件"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isAdverse.adverseTable.isSeriousEvent">
                <el-radio label="是">是</el-radio>
                <el-radio label="否">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="case_form.clinicalEvents.isAdverse.adverseTable.isSeriousEvent==='是'"
                          label="严重不良事件编号"
            >
              <el-input v-model="case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.yzEventNo"
                        placeholder="请输入"/>
            </el-form-item>
            <el-form-item v-if="case_form.clinicalEvents.isAdverse.adverseTable.isSeriousEvent==='是'"
                          label="SAE名称"
            >
              <el-input v-model="case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAEName" placeholder="请输入"/>
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isAdverse.adverseTable.isSeriousEvent==='是'"

                          label="发生日期"

            >
              <van-field
                  :value="case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.occurDate"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='occurDate'"
              />
            </el-form-item>
            <el-form-item v-if="case_form.clinicalEvents.isAdverse.adverseTable.isSeriousEvent==='是'"
                          label="获知日期"
                          prop="adverseTable.yzEvent.getDate"

            >
              <van-field
                  :value="case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.getDate"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='getDate'"
              />
            </el-form-item>
            <el-form-item v-if="case_form.clinicalEvents.isAdverse.adverseTable.isSeriousEvent==='是'"
                          label="报告日期"
                          prop="adverseTable.yzEvent.reportDate"

            >
              <van-field
                  :value="case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.reportDate"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='reportDate'"
              />
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isAdverse.adverseTable.isSeriousEvent==='是'"

                          label="报告类型"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isAdverse.adverseTable.reportType"
                              style="display: grid">
                <el-radio label="首次报告">首次报告</el-radio>
                <el-radio label="随访报告">随访报告</el-radio>
                <el-radio label="总结报告">总结报告</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="case_form.clinicalEvents.isAdverse.adverseTable.isSeriousEvent==='是'"

                          label="SAE情况"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAECondition"
                              style="display: grid">
                <el-radio label="导致死亡"></el-radio>
                <el-radio label="危及生命"></el-radio>
                <el-radio label="机体伤残导致生活自理能力丧失"></el-radio>
                <el-radio label="导致住院或住院时间明显延长"></el-radio>
                <el-radio label="导致畸形缺陷"></el-radio>
                <el-radio label="严重的医疗事件：可能危及受试者安全或可能需要医学干预以预防上述情况发生"
                          style="display: flex;align-items: center;"></el-radio>
                <el-radio label="其他 请说明"></el-radio>
              </el-radio-group>
              <el-input v-if="case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAECondition==='其他 请说明'"
                        v-model="case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAECondition03"
                        placeholder="请输入"/>
            </el-form-item>

            <el-form-item
                v-if="case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAECondition==='导致住院或住院时间明显延长'"

                label="入院日期"

            >
              <van-field
                  :value="case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAECondition01"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='SAECondition01'"
              />
            </el-form-item>
            <el-form-item
                v-if="case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAECondition==='导致住院或住院时间明显延长'"

                label="出院日期"

            >
              <van-field
                  :value="case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAECondition02"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='SAECondition02'"
              />
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isAdverse.adverseTable.isSeriousEvent==='是'"

                          label="SAE转归"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAEOutCome"
                              style="display: grid">
                <el-radio label="症状消失">症状消失</el-radio>
                <el-radio label="症状持续">症状持续</el-radio>
                <el-radio label="死亡">死亡</el-radio>
                <el-radio label="症状缓减">症状缓减</el-radio>
                <el-radio label="不详">不详</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAEOutCome==='症状消失'"
                          label="若选择症状消失，是否有后遗症"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAEOutCome01"
                              style="display: grid">
                <el-radio label="是">是</el-radio>
                <el-radio label="否">否</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAEOutCome==='症状消失'"

                          label="出院日期"

            >
              <van-field
                  :value="case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAEOutCome02"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='SAEOutCome02'"
              />
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isAdverse.adverseTable.isSeriousEvent==='是'"

                          label="与试验药物的关系"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.withDrug"
                              style="display: grid">
                <el-radio label="肯定有关">肯定有关</el-radio>
                <el-radio label="很可能有关">很可能有关</el-radio>
                <el-radio label="可能有关">可能有关</el-radio>
                <el-radio label="可能无关">可能无关</el-radio>
                <el-radio label="肯定无关 ">肯定无关</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="case_form.clinicalEvents.isAdverse.adverseTable.isSeriousEvent==='是'"

                          label="与手术/治疗的关系"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.withSurgery"
                              style="display: grid">
                <el-radio label="肯定有关">肯定有关</el-radio>
                <el-radio label="很可能有关">很可能有关</el-radio>
                <el-radio label="可能有关">可能有关</el-radio>
                <el-radio label="可能无关">可能无关</el-radio>
                <el-radio label="肯定无关 ">肯定无关</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="case_form.clinicalEvents.isAdverse.adverseTable.isSeriousEvent==='是'"

                          label="SAE报道情况（国内）"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAEReportingDomestic"
                              style="display: grid">
                <el-radio label="无">无</el-radio>
                <el-radio label="有">有</el-radio>
                <el-radio label="不详">不详</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="case_form.clinicalEvents.isAdverse.adverseTable.isSeriousEvent==='是'"

                          label="SAE报道情况（国外）"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAEReportingOverseas"
                              style="display: grid">
                <el-radio label="无">无</el-radio>
                <el-radio label="有">有</el-radio>
                <el-radio label="不详">不详</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="case_form.clinicalEvents.isAdverse.adverseTable.isSeriousEvent==='是'"
                          label="SAE发生及处理情况详细描述"


            >
              <el-input v-model="case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAEDescribe"
                        placeholder="请输入"/>
            </el-form-item>


            <el-form-item :rules="[{ required: true, message: '请选择一个选项', trigger: 'change' },]"
                          label="是否发生终点相关事件"
                          prop="isRelated.related"
            >
              <el-radio-group v-model="case_form.clinicalEvents.isRelated.related">
                <el-radio label="是">是</el-radio>
                <el-radio label="否">否</el-radio>
              </el-radio-group>
            </el-form-item>

          </el-form>
        </el-collapse-item>
        <el-collapse-item v-if="case_form.clinicalEvents.isRelated.related==='是'" name="15" title="终点事件表">
          <el-form
              :model="case_form.clinicalEvents.isRelated"
              label-position="top">

            <el-form-item v-if="case_form.clinicalEvents.isRelated.related==='是'"
                          label="是否发生死亡"
                          prop="relatedTable.isDie.die"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isRelated.relatedTable.isDie.die">
                <el-radio label="是">是</el-radio>
                <el-radio label="否">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="case_form.clinicalEvents.isRelated.relatedTable.isDie.die==='是'"
                          label="死亡日期"
                          prop="relatedTable.isDie.dieMessage.dieDate"

            >
              <van-field
                  :value="case_form.clinicalEvents.isRelated.relatedTable.isDie.dieMessage.dieDate"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='dieDate'"
              />
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isRelated.relatedTable.isDie.die==='是'"
                          label="死亡类型"
                          prop="relatedTable.isDie.dieMessage.dieType"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isRelated.relatedTable.isDie.dieMessage.dieType">
                <el-radio label="非心血管源性死亡 "></el-radio>
                <el-radio label="心血管源性死亡"></el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isRelated.relatedTable.isDie.die==='是'"
                          label="死亡前是否因病入院接收治疗"
                          prop="relatedTable.isDie.dieMessage.hospitalToBeforeDie"

            >
              <el-radio-group
                  v-model="case_form.clinicalEvents.isRelated.relatedTable.isDie.dieMessage.hospitalToBeforeDie">
                <el-radio label="是"></el-radio>
                <el-radio label="否"></el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item
                v-if="case_form.clinicalEvents.isRelated.relatedTable.isDie.dieMessage.hospitalToBeforeDie==='是'"
                label="入院日期"
                prop="inHospitalDate"

            >
              <van-field
                  :value="case_form.clinicalEvents.isRelated.relatedTable.isDie.dieMessage.inHospitalDate"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='inHospitalDate'"
              />
            </el-form-item>
            <el-form-item v-if="case_form.clinicalEvents.isRelated.related==='是'"
                          label="是否发生血运重建"
                          prop="relatedTable.isOccurred"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isRelated.relatedTable.isOccurred">
                <el-radio label="是"></el-radio>
                <el-radio label="否"></el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isRelated.relatedTable.isOccurred==='是'"
                          label="重建术类型"
                          prop="relatedTable.occurredType"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isRelated.relatedTable.occurredType">
                <el-radio label="PCI"></el-radio>
                <el-radio label="CABG"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="case_form.clinicalEvents.isRelated.relatedTable.isOccurred==='是'"
                          label="血运重建发生日期"
                          prop="relatedTable.occurredDate"

            >
              <van-field
                  :value="case_form.clinicalEvents.isRelated.relatedTable.occurredDate"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='occurredDate'"
              />
            </el-form-item>
            <el-form-item v-if="case_form.clinicalEvents.isRelated.relatedTable.isOccurred==='是'"
                          label="是否为紧急血运重建"
                          prop="relatedTable.isUrgentOccurred"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isRelated.relatedTable.isUrgentOccurred">
                <el-radio label="是"></el-radio>
                <el-radio label="否"></el-radio>
              </el-radio-group>
            </el-form-item>


            <el-form-item v-if="case_form.clinicalEvents.isRelated.relatedTable.isUrgentOccurred==='是'"
                          label="是否因紧急血运重建入院进行治疗"
                          prop="relatedTable.isUrgentOccurredHospital"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isRelated.relatedTable.isUrgentOccurredHospital">
                <el-radio label="是"></el-radio>
                <el-radio label="否"></el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isRelated.relatedTable.isUrgentOccurredHospital==='是'"
                          label="紧急血运重建入院日期"
                          prop="inHospitalDateUrgent"

            >
              <van-field
                  :value="case_form.clinicalEvents.isRelated.relatedTable.inHospitalDateUrgent"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='inHospitalDateUrgent'"
              />
            </el-form-item>


            <el-form-item v-if="case_form.clinicalEvents.isRelated.related==='是'"
                          label="是否发生脑卒中"
                          prop="relatedTable.isStroke.stroke"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isRelated.relatedTable.isStroke.stroke">
                <el-radio label="是"></el-radio>
                <el-radio label="否"></el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isRelated.relatedTable.isStroke.stroke==='是'"
                          label="卒中类型"
                          prop="relatedTable.isStroke.strokeType"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isRelated.relatedTable.isStroke.strokeType">
                <el-radio label="缺血性"></el-radio>
                <el-radio label="出血性"></el-radio>
                <el-radio label="不明原因"></el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isRelated.relatedTable.isStroke.stroke==='是'"
                          label="脑卒中发生日期"
                          prop="strokeDate"

            >
              <van-field
                  :value="case_form.clinicalEvents.isRelated.relatedTable.isStroke.strokeDate"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='strokeDate'"
              />
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isRelated.relatedTable.isStroke.stroke==='是'"
                          label="是否因脑卒中入院进行治疗"
                          prop="relatedTable.isStrokeHospital"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isRelated.relatedTable.isStrokeHospital">
                <el-radio label="是"></el-radio>
                <el-radio label="否"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="case_form.clinicalEvents.isRelated.relatedTable.isStrokeHospital==='是'"
                          label="因脑卒中入院日期"
                          prop="inHospitalDateStroke"

            >
              <van-field
                  :value="case_form.clinicalEvents.isRelated.relatedTable.inHospitalDateStroke"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='inHospitalDateStroke'"
              />
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isRelated.related==='是'"
                          label="是否发生不稳定心绞痛"
                          prop="relatedTable.isAngina"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isRelated.relatedTable.isAngina">
                <el-radio label="是"></el-radio>
                <el-radio label="否"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="case_form.clinicalEvents.isRelated.relatedTable.isAngina==='是'"
                          label="不稳定心绞痛发生日期"
                          prop="anginaDate"

            >
              <van-field
                  :value="case_form.clinicalEvents.isRelated.relatedTable.anginaDate"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='anginaDate'"
              />
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isRelated.relatedTable.isAngina==='是'"
                          label="是否因不稳定心绞痛入院进行治疗"
                          prop="relatedTable.isAnginaHospital"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isRelated.relatedTable.isAnginaHospital">
                <el-radio label="是"></el-radio>
                <el-radio label="否"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="case_form.clinicalEvents.isRelated.relatedTable.isAnginaHospital==='是'"
                          label="因不稳定心绞痛入院日期"
                          prop="inHospitalDateAngina"

            >
              <van-field
                  :value="case_form.clinicalEvents.isRelated.relatedTable.inHospitalDateAngina"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='inHospitalDateAngina'"
              />
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isRelated.related==='是'"
                          label="是否发生心肌梗死"
                          prop="relatedTable.isInfarction"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isRelated.relatedTable.isInfarction">
                <el-radio label="是"></el-radio>
                <el-radio label="否"></el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isRelated.relatedTable.isInfarction==='是'"
                          label="心肌梗死发生日期"
                          prop="infarctionDate"

            >
              <van-field
                  :value="case_form.clinicalEvents.isRelated.relatedTable.infarctionDate"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='infarctionDate'"
              />
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isRelated.relatedTable.isInfarction==='是'"
                          label="是否因心肌梗死入院进行治疗"
                          prop="relatedTable.isInfarctionHospital"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isRelated.relatedTable.isInfarctionHospital">
                <el-radio label="是"></el-radio>
                <el-radio label="否"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="case_form.clinicalEvents.isRelated.relatedTable.isInfarctionHospital==='是'"
                          label="因心肌梗死入院日期"
                          prop="inHospitalDateInfarction"

            >
              <van-field
                  :value="case_form.clinicalEvents.isRelated.relatedTable.inHospitalDateInfarction"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='inHospitalDateInfarction'"
              />
            </el-form-item>


            <el-form-item v-if="case_form.clinicalEvents.isRelated.related==='是'"
                          label="是否短暂性脑缺血发作"
                          prop="relatedTable.isAttack"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isRelated.relatedTable.isAttack">
                <el-radio label="是"></el-radio>
                <el-radio label="否"></el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isRelated.relatedTable.isAttack==='是'"
                          label="短暂性脑缺血发作发生日期"
                          prop="attackDate"

            >
              <van-field
                  :value="case_form.clinicalEvents.isRelated.relatedTable.attackDate"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='attackDate'"
              />
            </el-form-item>
            <el-form-item v-if="case_form.clinicalEvents.isRelated.relatedTable.isAttack==='是'"
                          label="是否因短暂性脑缺血发作入院进行治疗"
                          prop="relatedTable.isAnginaHospital"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isRelated.relatedTable.isAnginaHospital">
                <el-radio label="是"></el-radio>
                <el-radio label="否"></el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isRelated.relatedTable.isAnginaHospital==='是'"
                          label="因短暂性脑缺血发作入院日期"
                          prop="inHospitalDateAttack"

            >
              <van-field
                  :value="case_form.clinicalEvents.isRelated.relatedTable.inHospitalDateAttack"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='inHospitalDateAttack'"
              />
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isRelated.related==='是'"
                          label="是否发生支架内血栓"
                          prop="relatedTable.isStent"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isRelated.relatedTable.isStent">
                <el-radio label="是"></el-radio>
                <el-radio label="否"></el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isRelated.relatedTable.isStent==='是'"
                          label="支架内血栓发生日期"
                          prop="stentDate"

            >
              <van-field
                  :value="case_form.clinicalEvents.isRelated.relatedTable.stentDate"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='stentDate'"
              />
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isRelated.isStent==='是'"
                          label="血栓分类"
                          prop="relatedTable.stentType"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isRelated.relatedTable.stentType">
                <el-radio label="确定的"></el-radio>
                <el-radio label="很可能的"></el-radio>
                <el-radio label="可能的"></el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isRelated.related==='是'"
                          label="是否发生出血"
                          prop="relatedTable.isBlood"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isRelated.relatedTable.isBlood">
                <el-radio label="是"></el-radio>
                <el-radio label="否"></el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isRelated.relatedTable.isBlood==='是'"
                          label="出血发生日期"
                          prop="bloodDate"

            >
              <van-field
                  :value="case_form.clinicalEvents.isRelated.relatedTable.bloodDate"
                  clickable
                  placeholder="点击选择时间"
                  readonly
                  @click="showPicker = true; showPickerType='bloodDate'"
              />
            </el-form-item>

            <el-form-item v-if="case_form.clinicalEvents.isRelated.relatedTable.isBlood==='是'"
                          label="BARC类型"
                          prop="relatedTable.BARCBlood"

            >
              <el-radio-group v-model="case_form.clinicalEvents.isRelated.relatedTable.BARCBlood">
                <el-radio label="1型"></el-radio>
                <el-radio label="2型"></el-radio>
                <el-radio label="3型"></el-radio>
                <el-radio label="4型"></el-radio>
                <el-radio label="5型"></el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </el-collapse-item>
      </el-collapse>
      <h4 v-if="buttonType==='shcase'">驳回原因：{{ questionnaire_info.reason }}</h4>

      <div v-if="buttonType==='case'">
        <div v-if="case_form_status!=='PASSED' " style="display: flex;justify-content: center;margin-top: 5%">
          <el-button style="width: 40%" @click="addOk('STAGED')">暂存</el-button>
          <el-button v-show="addOk_show" style="width: 40%" type="primary" @click="addOk('PENDING')">
            提交
          </el-button>
        </div>

      </div>
      <div v-else style="display: flex;justify-content: center;margin-top: 5%">
        <el-button style="width: 40%" @click="dialogVisible=true">驳回</el-button>
        <el-button style="width: 40%" type="primary" @click="addOk('PASSED')">通过</el-button>
      </div>


      <el-dialog
          :visible.sync="dialogVisible"
          title="驳回原因"
          width="80%">
        <el-input v-model="reject_reason" placeholder="请输入内容"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false">取 消</el-button>
          <el-button size="small" type="primary" @click="addOk('DENIED')">确 定</el-button>
        </span>
      </el-dialog>
      <van-popup v-model="showPicker"
                 :style="{ height: '40%' }" position="bottom">
        <van-datetime-picker
            :max-date="maxDate"
            :min-date="minDate_2022"
            type="date"
            @cancel="showPicker = false"
            @confirm="date_select"
        />
      </van-popup>

      <van-popup v-model="showPicker_birth"
                 :style="{ height: '40%' }" position="bottom">
        <van-datetime-picker
            :max-date="maxDate"
            :min-date="minDate"
            type="date"
            @cancel="showPicker_birth = false"
            @confirm="date_select"
        />
      </van-popup>

      <van-popup v-model="showPicker_firstSurgeryDate"
                 :style="{ height: '40%' }" position="bottom">
        <van-datetime-picker
            :max-date="maxDate"
            :min-date="minDate2"
            type="date"
            @cancel="showPicker_firstSurgeryDate = false"
            @confirm="date_select_firstSurgeryDate"
        />
      </van-popup>

      <el-dialog
          :visible.sync="centerDialogVisible"
          center
          title="姓名缩写填写说明"
          width="90%">
        <div>1.两字姓名填写两字拼音前两个字母；举例：张红ZHHO;</div>
        <div>2.三字姓名填写三字首字母及第三字第二字母；举例：李淑明 LSMI;鄂美丽EMLI;</div>
        <div>
          3.四字姓名填写每一个字的首字母；举例：欧阳小慧OYXH;
        </div>
        <div>
          4.如果按以上规则仍无法取得四位姓名缩写时，则用“-”在最后补齐四位姓名缩写：举例：王莉娥WLE-；李娥LIE-；鄂娥EE--;
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
          :visible.sync="caseVisible"
          center
          :show-close="false"
          :close-on-click-modal="false"
          title="提示"
          width="90%">
        <div style="display: flex;justify-content: center;font-size: large">
          {{caseType===0?'该病例模板已关闭':'该病例收集数量已满'}}
        </div>
      </el-dialog>

    </div>
    <CHDInfo v-else></CHDInfo>

  </div>
</template>

<script>

import moment from "moment";
import CHDInfo from "../CHD/CHD_info.vue";
import {getCaseInfo, getCaseNumber, getCaseOpen, post_case, put_case, putSexPass} from "../../service/api";

export default {
  name: "CHD_survey",
  components: {CHDInfo},
  data() {
    const checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('总剂量不能为空'));
      }
      setTimeout(() => {
        if (value < 500) {
          callback(new Error('总剂量不能少于500mg'));
        } else {
          callback();
        }
      }, 500);
    };

    return {
      rules: {
        doesUses: [
          {validator: checkAge, trigger: 'change'}
        ]
      },
      caseType:0,
      caseVisible:false,
      centerDialogVisible: false,
      showPicker_firstSurgeryDate: false,
      showPicker_birth: false,
      dialogVisible: false,
      reject_reason: '',
      buttonType: 'case',
      isButton: "",
      addOk_show: false,
      //出生日期选择
      showPicker: false,
      showPickerType: '',
      maxDate: new Date(),
      minDate: new Date(1900, 0, 1),
      minDate2: new Date(2023, 4, 1),
      minDate_2022: new Date(2022, 0, 1),
      isShowDialog: false,
      activeName: '1',
      case_form_status: null,
      case_form: {
        drugLog: '',
        //病例报告确认
        caseReport: {
          PCI: '', //PCI围术期使用比伐芦定进行抗凝治疗的冠心病患者
          SigForm: '', //已签署书面知情同意书
          hirudin: '', //对比伐芦定及其辅料或水蛭素过敏者
          activeBleeding: '', //活动性出血者或存在出血性疾病导致出血风险增加，以及不可逆凝血功能障碍患者
          hypertension: '', //严重的未被控制的高血压
          subacute: '', //亚急性细菌性心内膜炎
          show: true,
        },
        //人口学资料
        demographic: {
          fullName: '',//姓名缩写（拼音首字母大写）
          birth: '', //出生日期
          patientNo: '',
          sex: '', //性别
          nation: '', //民族
          height: '', //身高cm
          weight: '', //体重Kg
          show: false,
        },
        //现病史及伴随疾病
        current: {
          show: false,
          curent: [],
          diabetesType: '', //糖尿病类型
          diabetesType_other: '',
          diabetesTreatment: [],  //糖尿病治疗方式（有）
          stroke: {
            str: '',
            strokeType: '' //卒中类型（有）
          },
          wkzls: { //外科治疗史
            wk: '',
            historySurgicalName: '', //外科治疗史——手术名称
            historySurgicalDate: '' //外科治疗史——手术日期
          },

          xhxkys: '', //消化性溃疡史
          gbs: { //肝病史
            gb: '', // 肝病史
            liverDamage: '' //肝功能损伤
          },
          mxsbs: {
            mxsb: '', //慢性肾病史
            tmfcd: '' //慢性肾病采取的治疗措施
          },
          isAsplOrADP: '', //是否有阿司匹林或ADP受体阻滞剂应用禁忌症
          planToSurgery: {
            planToSurgery: '', ////计划进行冠状动脉、脑血管或外周动脉血运重建手术
            surgeryName: '' //手术名称
          },
          majorSurgery: {
            majorSurgery: '', //计划进行心脏或非心脏大手术
            surgeryName: '' //手术名称
          },
          useCYP2C19: '', //计划联合使用CYP2C19强效或中效抑制剂
          vesselsBlood: '', // 颅内出血
          zssjxtzl: '', //中枢神经系统肿瘤
          bloodVessels: {
            bloodVessel: '', // 颅内血管异常
            bloodVesselsType: '' //颅内血管异常形式
          },
          smokes: {
            smoke: '', //吸烟史
            smokeYear: '', //吸烟年限
            isSmoke: '', //是否已戒烟
            smokeYearDie: '' //戒烟年限
          },
          beers: {
            beer: '', //饮酒史
            isBeer: '' //是否已戒酒
          },
          hyOrbr: '', //计划怀孕或者正在哺乳
          jsbs: '', //精神病史
          yzjb: {
            yzjb: '', //严重疾病
            severeDiseases: '' //预计生存期
          },
          inClinical: '' //  正在参与其它器械或药物临床试验，且未达到主要终点
        },
        intervene: { //冠脉介入操作
          show: false,
          bloodVessel_other: '',
          firstSurgeryDate: '', //第一次手术日期
          bloodVessel: '', //血管
          supportType: '', //支架类型
          supportNum: '', //支架数量
          isSecondSurgery: { //是否进行第二次手术
            bloodVessel_other: '',
            isSecondSurgery: '', // 是否进行第二次手术（如果是展示下面）
            secondSurgeryReason: '', //二次手术原因
            secondSurgeryDate: '', //第二次手术日期
            secondSurgeryBloodVessel: '', //第二次手术血管
            secondSurgerySupportType: '', //第二次手术支架类型
            secondSurgerySupportNum: '' //第二次手术支架数量
          }
        },
        vitalSigns: { //生命体征
          show: false,
          isVital: '是', //是否检查生命体征
          checkDate: '', //检查日期
          xy: {
            bloodPressure: '', //mmHg血压-收缩压
            bloodPressureDiastolic: '' //mmHg血压-舒张压
          },
          results: {
            checkResults: '', //检查结果（异常则填写异常描述）
            exception: '' //异常描述
          }
        },
        leadEcg: { //导联心电图（ECG）
          show: false,
          isEcg: '', //是否检查导联心电图（ECG）
          checkDate: '', //检查日期
          xl: '', //心率次/分
          result: {
            checkResults: '', //检查结果（如果为异常则填写异常描述）
            exception: '' //异常描述
          }
        },
        echo: { //超声心动图
          show: false,
          isEcho: '', //是否检查超声心动图
          checkDate: '', //检查日期
          leftLVEDD: '', //左室舒张末期内径（LVEDD）（单位：mm）
          bloodEF: '', //射血分数(EF)（单位：%）
          ECompareA: ''//E峰与A峰比值E/A
        },
        bloodRoutine: { //血常规
          show: false,
          isBloodRoutine: '是', //是否检查血常规
          checkDate: '', //检查日期
          WBCNum: { //白细胞总数（WBC)
            result: '', //结果
            unit: "×10⁹/L", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          NEUTNum: { //中性粒细胞计数（NEUT）
            result: '', //结果
            unit: "×10⁹/L", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          HB: { //血红蛋白（Hb)
            result: '', //结果
            unit: "g/L", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          RBCNum: { //红细胞总数（RBC）
            result: '', //结果
            unit: "×10¹²/L", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          PLT: { //血小板（PLT）
            result: '', //结果
            unit: "×10⁹/L", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          }
        },
        urinalysis: { //尿常规
          show: false,
          isUrinalysis: '是', //是否检查尿常规
          checkDate: '', //检查日期
          urineProtein: { //尿蛋白定性
            result: '', //结果
            unit: '', //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          urineSugar: { //尿糖
            result: '', //结果
            unit: '', //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          urineBlood: { //尿隐血
            result: '', //结果
            unit: '', //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          }
        },
        bloodGly: { //血生化+糖化血红蛋白
          show: false,
          isBloodGly: '', //否检查血生化+糖化血红蛋白
          checkDate: '', //检查日期
          ALT: { //谷丙转氨酶（ALT/GPT）（单位：IU/L）
            result: '', //结果
            unit: "U/L", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          AST: { //谷草转氨酶（AST/GOT）（单位：IU/L）
            result: '', //结果
            unit: "U/L", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          TC: { //总胆固醇（TC）（mmol/L）
            result: '', //结果
            unit: "mmol/L", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          TG: { //甘油三脂（TG）（单位：mmol/L）
            result: '', //结果
            unit: "mmol/L", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          LDL: { //低密度脂蛋白（LDL-C）（单位：mmol/L）
            result: '', //结果
            unit: "mmol/L", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          HDL: { //高密度脂蛋白（HDL-C）（单位：mmol/L）
            result: '', //结果
            "unit": "mmol/L", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          bloodSugar: { //空腹血糖（单位：mmol/L）
            result: '', //结果
            "unit": "mmol/L", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          CR: { //肌酐（Cr）
            result: '', //结果
            unit: '', //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          UA: { //血尿酸（UA）（单位：μmol/L）
            result: '', //结果
            "unit": "μmol/L", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          'K+': { //钾（K+）（单位：mmol/L）
            result: '', //结果
            "unit": "mmol/L", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          HBA1C: { //糖化血红蛋白（HbA1c）（单位：%）
            result: '', //结果
            "unit": "%", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          }
        },
        drugStatus: { //入组抗栓药物用药情况
          show: false,
          doesUse: '', //抗凝药物是否使用比伐芦定 “是”加每日剂量
          otherUse: [], //其他抗凝药物使用
          platelet: '', //血小板环氧化酶抑制药  "aa 4mg"
          ADP: [], //ADP受体拮抗剂 多项拼接  数组转字符串 "aa 4mg"
          phospate: []//磷酸二酯酶抑制药 多项拼接  数组转字符串 "aa 4mg"
        },
        ACT: { //ACT监测
          show: false,
          isACT: '', //是否检查ACT
          checkDate: '', //检查日期
          intra: { //术中（单位：S）
            result: '', //结果
            "unit": "s", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          intraTwoHours: { //术后2小时（单位：S）
            result: '', //结果
            "unit": "s", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          intraSixHours: { //术后6小时（单位：S）
            result: '', //结果
            "unit": "s", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          intraEightHours: { //术后8小时（单位：S）
            result: '', //结果
            unit: "s", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          intraTenHours: { //术后10小时（单位：S）
            result: '', //结果
            "unit": "s", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          other: { //其他（单位：S）
            result: '', //结果
            "unit": "s", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          }
        },

        followUP: { //随访信息
          show: false,
          followDate: '', //随访日期
          followMethod: '', //随访方式(未进行随访则填写原因)
          remark: '' //未进行随访原因

        },
        clinicalEvents: { //临床事件
          isFapl: { //是否发生方案偏离
            fapl: '', //(是：填写方案偏离表)
            faplTable: { //方案偏离表
              plNo: '', //偏离编号
              plDate: '', //偏离日期
              plIN: '',   //偏离发生于
              plcd: '',//偏离程度
              plms: [],//偏离描述）
              cxplyy: [] //出现偏离原因
            }
          },
          show: false,

          isAdverse: { //是否发生不良事件/严重不良事件
            adverse: '', //(是：填写不良事件表)
            adverseTable: { //不良事件表
              adverseNo: '', //不良事件编号
              adverseName: '', //不良事件名称
              adverseDescribe: '', //不良事件描述
              eventDate: '', //事件发生日期
              eventDieDate: '', //时间结束日期
              eventGrade: '', //不良事件分级
              withDrug: '',//与试验药物的关系
              withSurgery: '', //与手术/治疗的关系
              event: {
                eventTaken: '', //对不良事件采取的措施（有：填写不良事件采取的措施的记录）
                eventLog: '' //对不良事件采取的措施的记录
              },
              outCome: '', //不良事件转归
              isExitInEvent: '', //因不良事件而退出试验
              isSeriousEvent: '', //是否为严重不良事件（是：填写严重不良事件表）
              yzEvent: { //严重不良事件表
                yzEventNo: '', //严重不良事件编号
                SAEName: '', //SAE名称
                occurDate: '', //发生日期
                getDate: '', //获知日期
                reportDate: '', //报告日期
                reportType: '', //报告类型
                SAECondition: '',//SAE情况
                SAEOutCome: '',//SAE转归
                withDrug: '', //与试验药物的关系
                withSurgery: '',//与手术/治疗的关系
                SAEReportingDomestic: '',//SAE报道情况（国内）
                SAEReportingOverseas: '', //SAE报道情况（国外）
                SAEDescribe: '' //SAE发生及处理情况详细描述
              }
            }
          },
          isRelated: {
            related: '', //是否发生终点事件（是：填写终点事件表）
            relatedTable: { //终点事件表
              isDie: { //是否发生死亡
                die: '', //（是则填写死亡信息）
                dieMessage: { //死亡信息
                  dieType: '', //死亡类型
                  dieDate: '', //死亡日期
                  hospitalToBeforeDie: '', //死亡前是否因病入院接收治疗（是：填写入院日期）
                  inHospitalDate: '' //入院日期
                }
              },
              isOccurred: '', //是否发生血运重建
              occurredType: '', //重建术类型
              isUrgentOccurred: '', //是否为紧急血运重建
              occurredDate: '', //血运重建发生日期
              isUrgentOccurredHospital: '', //是否因紧急血运重建入院进行治疗(是：填写紧急血运重建入院日期)
              inHospitalDateUrgent: '', //紧急血运重建入院日期
              isStroke: { //是否发生脑卒中
                stroke: '', //是否发生脑卒中(是：填写卒中类型和脑卒中发生日期)
                strokeType: '', //卒中类型
                strokeDate: '' //脑卒中发生日期
              },
              isStrokeHospital: '', //是否因脑卒中入院进行治疗（是：填写因脑卒中入院日期）
              inHospitalDateStroke: '', //因脑卒中入院日期
              isAngina: '', //是否发生不稳定心绞痛（是：填写不稳定心绞痛发生日期）
              anginaDate: '', //不稳定心绞痛发生日期
              isAnginaHospital: '', //是否因不稳定心绞痛入院进行治疗（是：填写/因不稳定心绞痛入院日期）
              inHospitalDateAngina: '', //因不稳定心绞痛入院日期
              isInfarction: '', //是否发生心肌梗死（是：填写心肌梗死发生日期）
              infarctionDate: '', //心肌梗死发生日期
              isInfarctionHospital: '', //是否因心肌梗死入院进行治疗（是：因心肌梗死入院日期）
              inHospitalDateInfarction: '', //因心肌梗死入院日期
              isAttack: '', //是否短暂性脑缺血发作（是：短暂性脑缺血发作发生日期）
              attackDate: '', //短暂性脑缺血发作发生日期
              isAttackHospital: '', //是否因短暂性脑缺血发作入院进行治疗（是：短暂性脑缺血发作发生日期）
              inHospitalDateAttack: '', //因短暂性脑缺血发作日期
              isStent: '', //是否发生支架内血栓（是：支架内血栓发生日期和血栓分类）
              stentDate: '', //支架内血栓发生日期
              stentType: '', // 血栓分类
              isBlood: '', //是否发生出血(是：填写出血发生日期和BARC类型）
              bloodDate: '', //出血发生日期
              BARCBlood: '',//BARC类型
            }
          }
        },


      },
      questionnaire_info: null,
    }
  },
  watch: {
    case_form: {
      handler: function (newValue, oldValue) {
        //人口学资料
        newValue.demographic.show = newValue.caseReport.PCI === '是' && newValue.caseReport.SigForm === '是'
            && newValue.caseReport.hirudin === '否' && newValue.caseReport.activeBleeding === '否'
            && newValue.caseReport.hypertension === '否' && newValue.caseReport.subacute === '否';
        //现病史及伴随疾病
        newValue.current.show = newValue.demographic.birth !== '' && newValue.demographic.fullName !== '' && newValue.demographic.patientNo !== ''
            && newValue.demographic.sex !== '' && newValue.demographic.weight !== ''

        //冠脉介入操作
        newValue.intervene.show = newValue.current.curent.length !== 0

        //生命体征
        newValue.vitalSigns.show = newValue.intervene.firstSurgeryDate !== '' && newValue.intervene.isSecondSurgery.isSecondSurgery !== ''
        //导联心电图（ECG）
        newValue.leadEcg.show = newValue.vitalSigns.checkDate !== '' && newValue.vitalSigns.xy.bloodPressure !== '' && newValue.vitalSigns.xy.bloodPressureDiastolic !== ''
        // && newValue.vitalSigns.checkDate!=='' newValue.vitalSigns.xy.bloodPressure!=='' &&newValue.vitalSigns.xy.bloodPressureDiastolic!=='' && newValue.vitalSigns.results.checkResults!==''
        //超声心动图
        newValue.echo.show = (newValue.leadEcg.isEcg === '是' && newValue.leadEcg.xl !== '') || newValue.leadEcg.isEcg === '否'
        //&&newValue.leadEcg.checkDate!=='' newValue.leadEcg.xl!=='' &&newValue.leadEcg.result.checkResults!=='') ||newValue.leadEcg.isEcg==='否'
        //血常规
        newValue.bloodRoutine.show = (newValue.echo.isEcho === '是' && newValue.echo.bloodEF !== '') || newValue.echo.isEcho === '否'
        // && && newValue.echo.checkDate!=='' newValue.echo.leftLVEDD!==''
        // && newValue.echo.bloodEF!=='' && newValue.echo.ECompareA!=='') || newValue.echo.isEcho==='否'
        //尿常规
        newValue.urinalysis.show = newValue.bloodRoutine.WBCNum.result !== '' && newValue.bloodRoutine.NEUTNum.result !== ''
        // (newValue.bloodRoutine.isBloodRoutine==='是'&&newValue.bloodRoutine.checkDate!=='' &&
        //     newValue.bloodRoutine.WBCNum.result!=='' && newValue.bloodRoutine.NEUTNum.result!=='' && newValue.bloodRoutine.HB.result!=='' && newValue.bloodRoutine.RBCNum.result!==''
        //     && newValue.bloodRoutine.PLT.result!=='') ||
        //血生化+糖化血红蛋白
        newValue.bloodGly.show = newValue.urinalysis.checkDate !== ''
        // (newValue.urinalysis.isUrinalysis==='是'&& newValue.urinalysis.checkDate!=='' && newValue.urinalysis.urineProtein.result!==''
        //     && newValue.urinalysis.urineSugar.result!=='' && newValue.urinalysis.urineBlood.result!=='') ||
        //入组抗栓药物用药情况
        newValue.drugStatus.show = (newValue.bloodGly.isBloodGly === '是' && newValue.bloodGly.ALT.result
            && newValue.bloodGly.TC.result !== '' && newValue.bloodGly.TG.result !== '') || newValue.bloodGly.isBloodGly === '否'
        // && newValue.bloodGly.LDL.result!=='' && newValue.bloodGly.HDL.result!=='' &&
        // newValue.bloodGly.bloodSugar.result!==''  && newValue.bloodGly.CR.result!=='' && newValue.bloodGly.CR.unit!=='' && newValue.bloodGly.UA.result!==''
        // && newValue.bloodGly["K+"].result!=='' && newValue.bloodGly.HBA1C.result!==''
        //ACT监测
        newValue.ACT.show = (newValue.drugStatus.doesUse === '是' && newValue.drugStatus.doesUses !== undefined && newValue.drugStatus.doesUses !== '')
            || newValue.drugStatus.doesUse === '否'

        //随访信息
        newValue.followUP.show = newValue.ACT.isACT !== ''
        // (newValue.ACT.isACT==='是' && newValue.ACT.checkDate!=='' && newValue.ACT.intra.result!=='' &&
        //     newValue.ACT.intraTwoHours.result!=='' && newValue.ACT.intraSixHours.result!=='' && newValue.ACT.intraEightHours.result!=='' && newValue.ACT.intraTenHours.result!==''
        // )  ||
        //临床事件
        newValue.clinicalEvents.show = newValue.followUP.followMethod !== ''
        // (newValue.followUP.followMethod ==='未进行随访'&& newValue.followUP.remark!=='') ||
        // (newValue.followUP.followDate!=='' &&
        //终点事件
        // newValue.clinicalEvents.show2=(newValue.clinicalEvents.isAdverse.adverse==='是'
        //     && ((newValue.clinicalEvents.isAdverse.adverseTable.isSeriousEvent==='是' && newValue.clinicalEvents.isAdverse.adverseTable.yzEvent.SAEDescribe!=='') ||newValue.clinicalEvents.isAdverse.adverseTable.isSeriousEvent==='否' )
        // ) || newValue.clinicalEvents.isAdverse.adverse==='否'
        this.addOk_show = newValue.clinicalEvents.isRelated.related !== '' && newValue.clinicalEvents.isAdverse.adverse !== '' && newValue.clinicalEvents.isFapl.fapl !== ''
            && newValue.clinicalEvents.show === true && newValue.followUP.show === true && newValue.ACT.show === true && newValue.drugStatus.show === true
            && newValue.bloodGly.show === true && newValue.urinalysis.show === true && newValue.bloodRoutine.show === true
            && newValue.echo.show === true && newValue.leadEcg.show === true && newValue.vitalSigns.show === true && newValue.intervene.show === true
            && newValue.current.show === true
        // (newValue.clinicalEvents.isRelated.related==='是')&&((newValue.clinicalEvents.isRelated.relatedTable.isBlood==='是'
        //         &&newValue.clinicalEvents.isRelated.relatedTable.bloodDate!=='' && newValue.clinicalEvents.isRelated.relatedTable.BARCBlood!=='' )
        //     || newValue.clinicalEvents.isRelated.relatedTable.isBlood==='否') ||

      },
      deep: true
    }
  },

  mounted() {
    // this.questionnaire_info={ "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6ImY5NGE2ODUwLTQwMmEtMTFlYy1hOWQ4LTdmMzAzYTZhYzU4NiIsIm5hbWUiOiLmnY7kvKDliJoifQ.zKUZYkIFN-m7kjm7hrCEsH5z5y6MsGjW6KOroY2j66g",
    //   "patient_id": "0",
    //   "created_by": "f94a6850-402a-11ec-a9d8-7f303a6ac586",
    //   "template_id": "24",
    //   "template_link": "https://questionnaire.yunicu.com/#/chd/survey",
    //   "isSelect": "false",
    //   "id":"69379"
    // }


    this.questionnaire_info = this.$route.query
    document.title = this.$route.query.isSelect === "false" ? "比伐芦定在冠心病行PCI治疗患者中应用的真实世界研究" : "选择模板"

    if (this.questionnaire_info.type === "see") {
      this.isButton = this.questionnaire_info.type
      this.getCase(this.questionnaire_info.id, this.questionnaire_info.created_by)
    } else if (this.questionnaire_info.type === "shcase") {
      this.reject_reason = this.questionnaire_info.reason
      this.buttonType = 'shcase'
      this.getCase(this.questionnaire_info.id, this.questionnaire_info.created_by)
    }else {
      this.getCaseOpen()
    }
  },
  methods: {

    async getCaseOpen(){
      const data={
        templateId:this.questionnaire_info.template_id
      }
      const info = await getCaseOpen(data)
      if (info.data==='0'){
        this.caseType=0
        this.caseVisible=true
      }else if (info.data==='1'){
        const num=await getCaseNumber(data)
        if (num.data===1 || num.data===0){
          this.caseType=1
          this.caseVisible=true
        }
      }
    },


    //获取回显数据
    async getCase(id, memberId) {
      const info = await getCaseInfo(id, memberId)
      if (info.code === 0) {
        this.case_form_status = info.data.status
        const form = JSON.parse(JSON.stringify(info.data.template));
        if (form.current.curent.length !== 0) {
          const diabetesIndex = form.current.curent.indexOf('糖尿病');
          if (diabetesIndex !== -1) {
            form.current.diabetesTreatment.forEach((value, index) => {
              if (value.includes('其他')) {
                const [diabetesType, diabetesType_other] = value.split(':');
                form.current.diabetesType_other = diabetesType_other;
                form.current.diabetesTreatment[index] = diabetesType
              }
            });
          }
        }

        if (form.clinicalEvents.isFapl.faplTable.plms.length !== 0) {
          form.clinicalEvents.isFapl.faplTable.plms.forEach((value, index) => {
            if (value.includes('其他,请详细说明')) {
              const [string, other] = value.split(':');
              form.clinicalEvents.isFapl.faplTable.plms_other = other;
              form.clinicalEvents.isFapl.faplTable.plms[index] = string
            }
          });
        }
        if (form.clinicalEvents.isFapl.faplTable.cxplyy.length !== 0) {
          form.clinicalEvents.isFapl.faplTable.cxplyy.forEach((value, index) => {
            if (value.includes('其他,请详细说明')) {
              const [string, other] = value.split(':');
              form.clinicalEvents.isFapl.faplTable.cxplyy_other = other;
              form.clinicalEvents.isFapl.faplTable.cxplyy[index] = string
            }
          });
        }


        let reg1 = new RegExp("Kg", "g");
        let reg2 = new RegExp("cm", "g");
        form.demographic.weight = form.demographic.weight.replace(reg1, "");
        form.demographic.height = form.demographic.height.replace(reg2, "");

        //第一次手术血管
        if (form.intervene.bloodVessel.includes('其他')) {
          const [bloodVessel, bloodVessel_other] = form.intervene.bloodVessel.split(':');
          form.intervene.bloodVessel = bloodVessel;
          form.intervene.bloodVessel_other = bloodVessel_other;
        }

        if (form.intervene.isSecondSurgery.secondSurgeryBloodVessel.includes('其他')) {
          const [bloodVessel, bloodVessel_other] = form.intervene.isSecondSurgery.secondSurgeryBloodVessel.split(':');
          form.intervene.isSecondSurgery.secondSurgeryBloodVessel = bloodVessel;
          form.intervene.isSecondSurgery.bloodVessel_other = bloodVessel_other;
        }

        if (form.drugStatus.doesUse.includes('是')) {
          const [value, other] = form.drugStatus.doesUse.split(':');
          form.drugStatus.doesUse = value;
          form.drugStatus.doesUses = other;
        }

        if (form.drugStatus.otherUse !== '') {
          let value = form.drugStatus.otherUse.split(',')
          value.forEach((data, index) => {
            if (data.includes('低分子肝素')) {
              const [string, other] = data.split(':');
              form.drugStatus.otherUse01 = other;
              value[index] = string
            }
            if (data.includes('大分子肝素续灌')) {
              const [string, other] = data.split(':');
              form.drugStatus.otherUse02 = other;
              value[index] = string
            }
          })
          form.drugStatus.otherUse = value
        } else {
          form.drugStatus.otherUse = []
        }

        if (form.drugStatus.ADP !== '') {
          let value = form.drugStatus.ADP.split(',')
          value.forEach((data, index) => {
            if (data.includes('氯吡格雷')) {
              const [string, other] = data.split(':');
              form.drugStatus.ADP01 = other;
              value[index] = string
            }
            if (data.includes('替格瑞洛')) {
              const [string, other] = data.split(':');
              form.drugStatus.ADP02 = other;
              value[index] = string
            }
            if (data.includes('其他ADP受体拮抗剂')) {
              const [string, other] = data.split(':');
              form.drugStatus.ADP03 = other;
              value[index] = string
            }
          })
          form.drugStatus.ADP = value
        } else {
          form.drugStatus.ADP = []
        }

        if (form.drugStatus.phospate !== '') {

          let value = form.drugStatus.phospate.split(',')
          value.forEach((data, index) => {
            if (data.includes('双嘧达莫')) {
              const [string, other] = data.split(':');
              form.drugStatus.phospate02 = other;
              value[index] = string
            }
            if (data.includes('西洛他唑')) {
              const [string, other] = data.split(':');
              form.drugStatus.phospate03 = other;
              value[index] = string
            }
          })
          form.drugStatus.phospate = value
        } else {
          form.drugStatus.phospate = []
        }


        if (form.drugStatus.platelet.includes('阿司匹林')) {
          const [string, other] = form.drugStatus.platelet.split(':');
          form.drugStatus.platelet = string
          form.drugStatus.platelet01 = other === undefined ? '' : other;
        }


        if (form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAECondition.includes('其他 请说明')) {
          const [string, other] = form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAECondition.split(':');
          form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAECondition = string
          form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAECondition03 = other
        }

        if (form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAEOutCome.includes('症状消失')) {
          const [string, other1, other2] = form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAEOutCome.split(',');
          form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAEOutCome = string
          form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAEOutCome01 = other1.split(':')[1];
          form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAEOutCome02 = other2.split(':')[1];
        }

        this.case_form = form
      }
    },

    //日期选择
    date_select(time) {//日期选择
      switch (this.showPickerType) {

        case 'birth':
          this.case_form.demographic.birth = moment(time).format('YYYY-MM-DD');
          break;
        case 'historySurgicalDate':
          this.case_form.current.wkzls.historySurgicalDate = moment(time).format('YYYY-MM-DD');
          break;
        case 'firstSurgeryDate':
          this.case_form.intervene.firstSurgeryDate = moment(time).format('YYYY-MM-DD');
          break;
        case 'secondSurgeryDate':
          this.case_form.intervene.isSecondSurgery.secondSurgeryDate = moment(time).format('YYYY-MM-DD');
          break;
        case 'checkDate':
          this.case_form.vitalSigns.checkDate = moment(time).format('YYYY-MM-DD');
          break;
        case 'isEcg':
          this.case_form.leadEcg.checkDate = moment(time).format('YYYY-MM-DD');
          break;
        case 'echo':
          this.case_form.echo.checkDate = moment(time).format('YYYY-MM-DD');
          break;
        case 'bloodRoutine':
          this.case_form.bloodRoutine.checkDate = moment(time).format('YYYY-MM-DD');
          break;
        case 'urinalysis':
          this.case_form.urinalysis.checkDate = moment(time).format('YYYY-MM-DD');
          break;
        case 'bloodGly':
          this.case_form.bloodGly.checkDate = moment(time).format('YYYY-MM-DD');
          break;
        case 'ACT':
          this.case_form.ACT.checkDate = moment(time).format('YYYY-MM-DD');
          break;
        case 'followDate':
          this.case_form.followUP.followDate = moment(time).format('YYYY-MM-DD');
          break;
        case 'eventDate':
          this.case_form.clinicalEvents.isAdverse.adverseTable.eventDate = moment(time).format('YYYY-MM-DD');
          break;
        case 'eventDieDate':
          this.case_form.clinicalEvents.isAdverse.adverseTable.eventDieDate = moment(time).format('YYYY-MM-DD');
          break;
        case 'occurDate':
          this.case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.occurDate = moment(time).format('YYYY-MM-DD');
          break;
        case 'getDate':
          this.case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.getDate = moment(time).format('YYYY-MM-DD');
          break;
        case 'reportDate':
          this.case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.reportDate = moment(time).format('YYYY-MM-DD');
          break;
        case 'SAECondition01':
          this.case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAECondition01 = moment(time).format('YYYY-MM-DD');
          break;
        case 'SAECondition02':
          this.case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAECondition02 = moment(time).format('YYYY-MM-DD');
          break;
        case 'SAEOutCome02':
          this.case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAEOutCome02 = moment(time).format('YYYY-MM-DD');
          break;
        case 'dieDate':
          this.case_form.clinicalEvents.isRelated.relatedTable.isDie.dieMessage.dieDate = moment(time).format('YYYY-MM-DD');
          break;
        case 'inHospitalDate':
          this.case_form.clinicalEvents.isRelated.relatedTable.isDie.dieMessage.inHospitalDate = moment(time).format('YYYY-MM-DD');
          break;
        case 'occurredDate':
          this.case_form.clinicalEvents.isRelated.relatedTable.occurredDate = moment(time).format('YYYY-MM-DD');
          break;
        case 'inHospitalDateUrgent':
          this.case_form.clinicalEvents.isRelated.relatedTable.inHospitalDateUrgent = moment(time).format('YYYY-MM-DD');
          break;
        case 'strokeDate':
          this.case_form.clinicalEvents.isRelated.relatedTable.isStroke.strokeDate = moment(time).format('YYYY-MM-DD');
          break;
        case 'inHospitalDateStroke':
          this.case_form.clinicalEvents.isRelated.relatedTable.inHospitalDateStroke = moment(time).format('YYYY-MM-DD');
          break;
        case 'anginaDate':
          this.case_form.clinicalEvents.isRelated.relatedTable.anginaDate = moment(time).format('YYYY-MM-DD');
          break;
        case 'inHospitalDateAngina':
          this.case_form.clinicalEvents.isRelated.relatedTable.inHospitalDateAngina = moment(time).format('YYYY-MM-DD');
          break;
        case 'infarctionDate':
          this.case_form.clinicalEvents.isRelated.relatedTable.infarctionDate = moment(time).format('YYYY-MM-DD');
          break;
        case 'inHospitalDateInfarction':
          this.case_form.clinicalEvents.isRelated.relatedTable.inHospitalDateInfarction = moment(time).format('YYYY-MM-DD');
          break;
        case 'attackDate':
          this.case_form.clinicalEvents.isRelated.relatedTable.attackDate = moment(time).format('YYYY-MM-DD');
          break;
        case 'inHospitalDateAttack':
          this.case_form.clinicalEvents.isRelated.relatedTable.inHospitalDateAttack = moment(time).format('YYYY-MM-DD');
          break;
        case 'stentDate':
          this.case_form.clinicalEvents.isRelated.relatedTable.stentDate = moment(time).format('YYYY-MM-DD');
          break;
        case 'bloodDate':
          this.case_form.clinicalEvents.isRelated.relatedTable.bloodDate = moment(time).format('YYYY-MM-DD');
          break;
        case 'plDate':
          this.case_form.clinicalEvents.isFapl.faplTable.plDate = moment(time).format('YYYY-MM-DD');
          break;

      }
      this.showPicker = false
      this.showPicker_birth = false
    },

    date_select_firstSurgeryDate(time) {
      switch (this.showPickerType) {
        case 'firstSurgeryDate':
          this.case_form.intervene.firstSurgeryDate = moment(time).format('YYYY-MM-DD');
          break;
        case 'secondSurgeryDate':
          this.case_form.intervene.isSecondSurgery.secondSurgeryDate = moment(time).format('YYYY-MM-DD');
          if (this.case_form.intervene.firstSurgeryDate > this.case_form.intervene.isSecondSurgery.secondSurgeryDate) {
            this.$message.error('第二次手术日期必须大于第一次手术日期')
            this.case_form.intervene.isSecondSurgery.secondSurgeryDate = ''
          }
          break;
      }
      this.showPicker_firstSurgeryDate = false
    },

    curent_change() {
      this.case_form.current.curent.find(value => {
        if (value === '卒中') {
          this.case_form.current.stroke.str = '有'
        } else if (value === '外科手术史') {
          this.case_form.current.wkzls.wk = '有'
        } else if (value === '肝病史') {
          this.case_form.current.gbs.gb = '有'
        } else if (value === '消化性溃疡史') {
          this.case_form.current.xhxkys = '有'
        } else if (value === '慢性肾病史') {
          this.case_form.current.mxsbs.mxsb = '有'
        } else if (value === '有阿司匹林或ADP受体阻滞剂应用禁忌症') {
          this.case_form.current.isAsplOrADP = '有'
        } else if (value === '计划进行冠状动脉、脑血管或外周动脉血运重建手术') {
          this.case_form.current.planToSurgery.planToSurgery = '有'
        } else if (value === '计划进行心脏或非心脏大手术') {
          this.case_form.current.majorSurgery.majorSurgery = '有'
        } else if (value === '计划联合使用CYP2C19强效或中效抑制剂') {
          this.case_form.current.useCYP2C19 = '有'
        } else if (value === '颅内出血') {
          this.case_form.current.vesselsBlood = '有'
        } else if (value === '中枢神经系统肿瘤') {
          this.case_form.current.zssjxtzl = '有'
        } else if (value === '颅内血管异常') {
          this.case_form.current.bloodVessels.bloodVessel = '有'
        } else if (value === '吸烟史') {
          this.case_form.current.smokes.smoke = '有'
        } else if (value === '饮酒史') {
          this.case_form.current.beers.beer = '有'
        } else if (value === '计划怀孕或者正在哺乳') {
          this.case_form.current.hyOrbr = '有'
        } else if (value === '精神病史') {
          this.case_form.current.jsbs = '有'
        } else if (value === '严重疾病') {
          this.case_form.current.yzjb.yzjb = '有'
        } else if (value === '正在参与其它器械或药物临床试验，且未达到主要终点') {
          this.case_form.current.inClinical = '有'
        }
      })
    },

    async addOk(status) {
      const form = JSON.parse(JSON.stringify(this.case_form));


      form.demographic.weight = form.demographic.weight !== '' ? form.demographic.weight + 'Kg' : '';
      form.demographic.height = form.demographic.height !== '' ? form.demographic.height + 'cm' : '';
      this.curent_change()

      const diabetesIndex = form.current.curent.indexOf('糖尿病');
      if (diabetesIndex !== -1) {
        if (form.current.diabetesTreatment.includes('其他')) {
          form.current.diabetesTreatment = form.current.diabetesTreatment.map(value => {
            return value === '其他' ? value + ':' + form.current.diabetesType_other : value;
          });
          delete form.current.diabetesType_other;
        }
      }
      //第一次手术血管
      if (form.intervene.bloodVessel === '其他') {
        form.intervene.bloodVessel = '其他:' + form.intervene.bloodVessel_other
        delete form.intervene.bloodVessel_other;
      }
      //第二次手术血管
      if (form.intervene.isSecondSurgery.secondSurgeryBloodVessel === '其他') {
        form.intervene.isSecondSurgery.secondSurgeryBloodVessel = '其他:' + form.intervene.isSecondSurgery.bloodVessel_other
        delete form.intervene.isSecondSurgery.bloodVessel_other
      }
      if (form.drugStatus.doesUse === '是') {
        form.drugStatus.doesUses = form.drugStatus.hasOwnProperty('doesUses') ? form.drugStatus.doesUses : '';

        form.drugStatus.doesUse = '是:' + form.drugStatus.doesUses
        delete form.drugStatus.doesUses;
      }
      if (form.drugStatus.otherUse.length !== 0) {
        form.drugStatus.otherUse01 = form.drugStatus.hasOwnProperty('otherUse01') ? form.drugStatus.otherUse02 : '';
        form.drugStatus.otherUse02 = form.drugStatus.hasOwnProperty('otherUse02') ? form.drugStatus.otherUse02 : '';
        form.drugStatus.otherUse = form.drugStatus.otherUse.map(value => {
          if (value === '低分子肝素') {
            return value + ':' + form.drugStatus.otherUse01;
          }
          if (value === '大分子肝素续灌') {
            return value + ':' + form.drugStatus.otherUse02;
          }
          return value;
        });

        form.drugStatus.otherUse = form.drugStatus.otherUse.toString()
      } else {
        form.drugStatus.otherUse = ""
      }
      delete form.drugStatus.otherUse01;
      delete form.drugStatus.otherUse02;

      if (form.drugStatus.platelet === '阿司匹林') {
        form.drugStatus.platelet01 = form.drugStatus.hasOwnProperty('platelet01') ? form.drugStatus.platelet01 : '';

        form.drugStatus.platelet = form.drugStatus.platelet + ':' + form.drugStatus.platelet01
      }
      delete form.drugStatus.platelet01;
      if (form.drugStatus.ADP.length !== 0) {
        form.drugStatus.ADP01 = form.drugStatus.hasOwnProperty('ADP01') ? form.drugStatus.ADP01 : '';
        form.drugStatus.ADP02 = form.drugStatus.hasOwnProperty('ADP02') ? form.drugStatus.ADP02 : '';
        form.drugStatus.ADP03 = form.drugStatus.hasOwnProperty('ADP03') ? form.drugStatus.ADP03 : '';


        form.drugStatus.ADP = form.drugStatus.ADP.map(value => {
          if (value === '氯吡格雷') {
            return value + ':' + form.drugStatus.ADP01;
          }
          if (value === '替格瑞洛') {
            return value + ':' + form.drugStatus.ADP02;
          }
          if (value === '其他ADP受体拮抗剂') {
            return value + ':' + form.drugStatus.ADP03;
          }
          return value;
        });

        form.drugStatus.ADP = form.drugStatus.ADP.toString()
      } else {
        form.drugStatus.ADP = ""
      }
      delete form.drugStatus.ADP01;
      delete form.drugStatus.ADP02;
      delete form.drugStatus.ADP03;

      if (form.drugStatus.phospate.length !== 0) {
        form.drugStatus.phospate02 = form.drugStatus.hasOwnProperty('phospate02') ? form.drugStatus.phospate02 : '';
        form.drugStatus.phospate03 = form.drugStatus.hasOwnProperty('phospate03') ? form.drugStatus.phospate03 : '';
        form.drugStatus.phospate = form.drugStatus.phospate.map(value => {
          if (value === '双嘧达莫') {
            return value + ':' + form.drugStatus.phospate02;
          }
          if (value === '西洛他唑') {
            return value + ':' + form.drugStatus.phospate03;
          }
          return value;
        });

        form.drugStatus.phospate = form.drugStatus.phospate.toString()
      } else {
        form.drugStatus.phospate = ""
      }
      delete form.drugStatus.phospate02;
      delete form.drugStatus.phospate03;

      if (form.clinicalEvents.isFapl.faplTable.plms.length !== 0) {
        form.clinicalEvents.isFapl.faplTable.plms = form.clinicalEvents.isFapl.faplTable.plms.map(value => {
          if (value === '其他,请详细说明') {
            return value + ':' + form.clinicalEvents.isFapl.faplTable.plms_other;
          }
          return value;
        });

      }
      delete form.clinicalEvents.isFapl.faplTable.plms_other;

      if (form.clinicalEvents.isFapl.faplTable.cxplyy.length !== 0) {
        form.clinicalEvents.isFapl.faplTable.cxplyy = form.clinicalEvents.isFapl.faplTable.cxplyy.map(value => {
          if (value === '其他,请详细说明') {
            return value + ':' + form.clinicalEvents.isFapl.faplTable.cxplyy_other;
          }
          return value;
        });

      }
      delete form.clinicalEvents.isFapl.faplTable.cxplyy_other;

      if (form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAECondition === '其他 请说明') {
        form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAECondition =
            form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAECondition + ':' +
            form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAECondition03;

      }
      delete form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAECondition03;

      if (form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAECondition === '导致住院或住院时间明显延长') {
        form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAECondition =
            form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAECondition + ',入院日期:' + form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAECondition01
            + ',出院日期:' + form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAECondition02;
        delete form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAECondition01;
        delete form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAECondition02;
      }

      if (form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAEOutCome === '症状消失') {
        form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAEOutCome =
            form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAEOutCome + ',是否有后遗症:' + form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAEOutCome01
            + ',出院时间:' + form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAEOutCome02
        delete form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAEOutCome02;
        delete form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAEOutCome01;
      }
      if (this.buttonType === "shcase") {
        const data = {
          "template_content": form,
          "audit_status": status,
          "reject_reason": this.reject_reason
        }
        if (status === "PASSED") {
          delete data.reject_reason
        }
        const put = await putSexPass(this.questionnaire_info.id, data)
        if (put.code === 0) {
          this.$message.success('操作成功')
        } else {
          this.$message.success('操作失败' + put.message)
        }
      } else {
        if (this.isButton === "see") {
          const form_value = {
            "id": this.questionnaire_info.id,  //问卷ID
            "status": status, //STAGED：暂存 PENDING：待审核
            "template": form   //问卷内容
          }
          const put = await put_case(form_value)
          if (put.code === 0) {
            this.$message.success(status === 'STAGED' ? '暂存成功' : '提交成功')
          } else if (put.code === -1) {
            this.$message.error('该患者住院号对应病例已上传，不可重复提交')
          } else {
            this.$message.error(status === 'STAGED' ? "暂存失败!" + put.message : '提交成功' + put.message)
          }
        } else {
          const data = {
            templateId: this.questionnaire_info.template_id,
            templateLink: this.questionnaire_info.template_link,
            // patient_id:0,
            memberId: this.questionnaire_info.created_by,
            auditStatus: status,
            templateContent: form
          }
          const add = await post_case(data)
          if (add.code === 0) {
            this.$message.success(status === 'STAGED' ? '暂存成功' : '提交成功')
          } else if (add.code === -1) {
            this.$message.error('该患者住院号对应病例已上传，不可重复提交')
          } else {
            this.$message.error(status === 'STAGED' ? "暂存失败!" + add.message : '提交失败' + add.message)
          }
        }
      }
    },

  },
}
</script>

<style lang="scss" scoped>
::v-deep .van-cell {
  background-color: #f7f8fc;
  border: 1px solid #f7f8fc;
}

::v-deep .el-checkbox {
  white-space: normal;
}

::v-deep .el-input__inner {
  background-color: #f7f8fc;
  border: 1px solid #f7f8fc;
}

.isUrinalysis {
  ::v-deep .el-input__inner {
    padding: 0;
    background-color: white;
    border: 1px solid white;
  }
}

::v-deep .el-radio {
  margin: 5px;
  white-space: normal;
}

.CHD_survey {
  margin-bottom: 60px;
  margin-top: -20px;

  h2 {
    color: #1ea0fa;
    font-weight: bold;
  }

  ::v-deep .el-form-item__label {
    padding: 0;
    text-align: left;
    line-height: normal;
    margin-bottom: 20px;
  }

  ::v-deep .el-radio-group {
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
  }
}
</style>
